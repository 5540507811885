import React, {ReactNode, useEffect, useState} from "react";
import Slider from 'rc-slider'

export type SliderOptionType = {
    label: string
    icon?: ReactNode;
    description?: ReactNode
}

type OptionSliderProps = {
    options: SliderOptionType[];
    defaultValue?: number;
    mobileViewHeight?: number;
    onChange?: (value: number) => void;
}

const MOBILE_WIDTH = 992;

export const OptionSlider = (props: OptionSliderProps)=> {
    const {options, mobileViewHeight = 150, onChange, defaultValue} = props;
    const [vertical, setVertical] = useState(false);

    const prepareOptions = () : Record<number, ReactNode>  => {
        const width = (100/options.length);
        const marks = options.map(item => (
            <div className="d-block text-truncate" style={{width: `${!vertical ? width : '100'}%`}}>
                {item.icon}
                <span title={item.label}>{item.label}</span>
                {item.description}
            </div>
        ))
        const finalOptions: Record<number, ReactNode> = {};
        marks.forEach((value, index) => {
            finalOptions[index+1] = value;
        })
        return finalOptions;
    }

    useEffect(() => {
        if(window.innerWidth < MOBILE_WIDTH) {
            setVertical(true);
        }
    }, [])

    return (
        <div className="mt-5 option-slider"  style={{height: vertical ? mobileViewHeight : 'auto'}}>
            <Slider className={`slider-${vertical ? 'vertical' : 'horizontal'}`}
                    marks={prepareOptions()}
                    step={1}
                    min={1}
                    max={Object.keys(options).length}
                    dots={true}
                    vertical={vertical}
                    defaultValue={defaultValue}
                    onChange={onChange}
            />
        </div>

    )
}