export class StorageUtil {
	static setItem(key: string, data: string | number) {
		localStorage.setItem(key, String(data));
	}

	static getItem(key: string) {
		return localStorage.getItem(key);
	}

	static removeItem(key: string) {
		localStorage.removeItem(key);
	}

	static clear() {
		localStorage.clear();
	}
}
