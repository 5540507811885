import React, {Fragment, useCallback, useEffect, useState} from 'react';
import {Collapse, NavItem, NavLink} from 'reactstrap';
import {NavLink as ReactRouterNavLink} from 'react-router-dom';
import {Icon} from '../Icon';
import {SidebarMenuItem} from './Sidebar';

type SidebarItemProps = {
	items: SidebarMenuItem;
	depth?: number;
	defaultOpenMenu: string;
	svgIconPath: string;
	searchTerm: string;
	checkIfRootMatchesRoute?: (root: SidebarMenuItem) => void,
}

export const SidebarItem = (props: SidebarItemProps) => {
	const {items, depth = 0, defaultOpenMenu, svgIconPath, searchTerm, checkIfRootMatchesRoute} = props;
	const [expanded, setExpanded] = useState(items.title === defaultOpenMenu);

	const toggleExpanded = useCallback(() => {
		setExpanded(currentExpanded => !currentExpanded);
	}, []);

	const getFormattedTitle = useCallback(() => {
		const title = items.title.toLowerCase();
		const term = searchTerm.toLowerCase();
		const fromIndex = title.indexOf(term);
		const toIndex = fromIndex + term.length;

		const initialPortion = items.title.slice(0, fromIndex);
		const highlightedPortion = items.title.slice(fromIndex, toIndex);
		const endingPortion = items.title.slice(toIndex, items.title.length);
		if (fromIndex !== -1) {
			return <>
                <span className={`${initialPortion.length > 0 ? 'text-capitalize' : ''}`}>
                    {initialPortion}
                </span>
				<span className={`search-highlight ${initialPortion.length === 0 ? 'text-capitalize' : ''}`}>
                    {highlightedPortion}
                </span>
				<span className={`${initialPortion.length === 0 && highlightedPortion.length === 0 ? 'text-capitalize' : ''}`}>
                    {endingPortion}
                </span>
			</>;
		} else {
			return <span>{items.title}</span>;
		}
	}, [items.title, searchTerm]);

	useEffect(() => {
		if (items.title === defaultOpenMenu || (checkIfRootMatchesRoute && checkIfRootMatchesRoute(items))) {
			setExpanded(true);
		} else if (searchTerm.length > 0) {
			setExpanded(prev => !prev ? true : prev);
		} else if (searchTerm.length === 0) {
			setExpanded(false);
		}
	}, [checkIfRootMatchesRoute, defaultOpenMenu, items, searchTerm.length]);

	if (Array.isArray(items.items)) {
		return (
			<Fragment>
				<NavItem onClick={toggleExpanded}
						 className={`${expanded ? 'menu-open' : ''}`}>
					<NavLink className={`dropdown-toggle ${depth === 0 ? 'show-arrow' : ''}`}>
						{
							depth === 0 && items.icon ?
								<Icon className="me-2" iconSpritePath={svgIconPath} fill={'#666'} width={20} height={20}
									  name={items.icon}/> :
								<Icon className="me-2" iconSpritePath={svgIconPath} fill={'#666'} width={10} height={10}
									  name={expanded ? 'minus' : 'plus'}/>
						}
						<span className="dropdown-item-header">
                            {getFormattedTitle()}
                        </span>
						<small className="custom-tooltip" data-title={items.title}/>
					</NavLink>
				</NavItem>
				<Collapse isOpen={expanded} navbar
						  className="items-menu">
					{items.items.map((subItem: any, index: number) => (
						<SidebarItem defaultOpenMenu={defaultOpenMenu} key={index} svgIconPath={svgIconPath}
									 checkIfRootMatchesRoute={checkIfRootMatchesRoute}
									 depth={depth + 1}
									 searchTerm={searchTerm}
									 items={subItem}/>
					))}
				</Collapse>
			</Fragment>
		);
	} else {
		return (
			<NavItem>
				<NavLink className={`${depth !== 0 ? 'px-5' : ''} d-flex align-items-center`} tag={ReactRouterNavLink}
						 to={items.target}>
					{
						depth === 2 ?
							<Icon className="" iconSpritePath={svgIconPath} fill={'#666'} width={20}
								  height={20}
								  name="chevron-right"/> :
							<Icon className="me-2" iconSpritePath={svgIconPath} fill={'#666'} width={10}
								  height={10}
								  name={items.icon ? items.icon : expanded ? 'minus' : 'plus'}/>
					}
					<span>{getFormattedTitle()}</span>
				</NavLink>
			</NavItem>
		);
	}
};
