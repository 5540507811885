import React, {Context, useContext} from 'react';
import {createPortal} from 'react-dom';
import {DropdownMenu} from 'reactstrap';
import {Backdrop} from '../ideascale-sidebar/Backdrop';

type DropdownWrapperProps = {
	containerId: string;
	className?: string;
	placement?: 'left' | 'right';
}

export const DropdownWrapper = (props: DropdownWrapperProps) => {
	const {containerId, className, placement = 'right'} = props;
	const {isOpen} = useContext(DropdownMenu.contextType as Context<{ isOpen: boolean }>);

	return createPortal(
		<div className={`drawer-able containerized-dropdown ${className} ${isOpen ? 'show' : ''}`}>
			<Backdrop/>
			<div className={`${placement}-panel`} id={containerId}/>
		</div>, document.getElementById('drawer-able-dropdown-wrapper')!);
};