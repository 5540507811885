import React, {useRef, useState} from 'react';
import Cropper from 'react-cropper';
import {Modal} from './Modal';
import {Spinner} from './progress-indicators';
import {Icon} from './Icon';
import {CropperjsGif} from '../utils/CropperJs-Gif/CropperJsGif';
import {GifWorker} from '../utils/CropperJs-Gif/GifWorker';

type CropperModalProps = {
	isOpen: boolean;
	toggle: (nextValue?: boolean) => void;
	title: string;
	saveButtonText: string;
	closeButtonText: string;
	file: File | undefined;
	cropHeight?: number;
	cropWidth?: number;
	handleUpload: (files: FileList | File[]) => Promise<void>;
	svgIconPath: string;
	loadingSvg: string;
	circle?: boolean;
	fillColor?: string;
	autoCropArea?: number;
}

export const CropperModal = (props: CropperModalProps) => {
	const {
		isOpen,
		toggle,
		title,
		saveButtonText,
		closeButtonText,
		file,
		cropHeight,
		cropWidth,
		handleUpload,
		svgIconPath,
		loadingSvg,
		circle = false,
		fillColor = '#fff',
		autoCropArea = null
	} = props;

	const cropperRef = useRef<HTMLImageElement>(null);
	const [loading, setLoading] = useState(false);

	const onCrop = () => {
		setLoading(true);
		const imageElement: any = cropperRef?.current;
		const cropper: any = imageElement?.cropper;
		if (file && cropper) {
			let fileType = file.type;
			let fileName = file.name;
			if (file.type === 'image/gif') {
				CropperjsGif.crop({
						encoder: {
							workers: 2,
							quality: 10,
							workerScript: GifWorker.getWorkerUrl()
						},
						src: URL.createObjectURL(file),
						background: fillColor,
						maxWidth: cropWidth ?? 100,
						maxHeight: cropHeight ?? 100,
						onerror: (errorCode) => console.log(errorCode)
					},
					cropper,
					(blob) => {
						const imageFile = new File([blob], fileName, {type: fileType});
						const imageList = [];
						imageList.push(imageFile);
						handleUpload(imageList)
							.then();
						setLoading(false);
						toggle(false);
					});
			} else {
				if (file.type === 'image/bmp') {
					fileType = 'image/png';
					fileName = file.name.slice(0, file.name.length - 4).concat('.png');
				}
				const canvas = cropper.getCroppedCanvas({
					imageSmoothingEnabled: true,
					imageSmoothingQuality: 'high'
				});
				canvas.toBlob((blob: Blob) => {
					const imageFile = new File([blob], fileName, {type: fileType});
					const imageList = [];
					imageList.push(imageFile);
					handleUpload(imageList)
						.then();
				}, fileType);
				setLoading(false);
				toggle(false);
			}
		}
	};

	const zoomIn = () => {
		const imageElement: any = cropperRef?.current;
		const cropper: any = imageElement?.cropper;
		cropper.zoom(.1);
	};

	const zoomOut = () => {
		const imageElement: any = cropperRef?.current;
		const cropper: any = imageElement?.cropper;
		cropper.zoom(-.1);
	};

	return (
		<Modal className="image-cropper" isOpen={isOpen} title={title} size="lg" toggle={toggle} showHeaderCloseButton={false}>
			{
				loading ? (
					<div className="d-flex justify-content-center py-5">
						<Spinner src={loadingSvg} size={100}/>
					</div>
				) : (
					file &&
					<Cropper
						className={`image-container ${circle ? 'circle' : ''}`}
						src={URL.createObjectURL(file)}
						ref={cropperRef}
						dragMode={'move'}
						viewMode={0}
						cropBoxResizable={true}
						cropBoxMovable={true}
						modal={true}
						highlight={true}
						guides={true}
						center={true}
						responsive={false}
						restore={false}
						{...((cropWidth && cropHeight) && {aspectRatio: (cropWidth / cropHeight)})}
						{...autoCropArea && {autoCropArea}}
					/>
				)
			}
			<div className="d-flex flex-row pt-2">
				<button className="btn btn-secondary" type="button" onClick={zoomIn} disabled={loading} title='Zoom in' lang='en'>
					<Icon iconSpritePath={svgIconPath} name="magnifying-glass-plus" width={18} height={18} fill="#384EC1"/>
				</button>
				<button className="btn btn-secondary ms-2" type="submit" onClick={zoomOut} disabled={loading} title='Zoom out' lang='en'>
					<Icon iconSpritePath={svgIconPath} name="magnifying-glass-minus" width={18} height={18} fill="#384EC1"/>
				</button>
			</div>
			<div className="d-flex flex-row justify-content-end mb-3">
				<button className="btn btn-cancel" type="button" onClick={() => toggle(false)} disabled={loading}>
					{closeButtonText}
				</button>
				<button className="btn btn-primary" type="button" onClick={onCrop} disabled={loading}>
					{saveButtonText}
				</button>
			</div>
		</Modal>
	);
};