import React from "react";

type propsType = {
    href: string;
    imageLink: string;
    label: string;
    className?: string;
}
export const GroupDisplay = (props: propsType) => {
    const {
        href,
        imageLink,
        label,
        className = '',
    } = props;

    return (
        <a href={href} className={`groups-display ${className ? className : ""}`}>
            <img src={imageLink}/>
            <span>{label}</span>
        </a>
    )

};
