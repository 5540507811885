import React from 'react';

type BackdropProps = {
    clicked?: () => void;
}

export const Backdrop = ({clicked}: BackdropProps) => {
    return (
        <div className="panel-backdrop" onClick={clicked}/>
    );
};
