import React, {ElementType, HTMLAttributes} from 'react';

type DividerProps = {
    tag?: ElementType,
    dividerText?: string
} & HTMLAttributes<ElementType>

export const Divider = (props: DividerProps) => {
    const {tag: Tag = 'div', dividerText = 'OR', className, role = 'separator', ...rest} = props;

    return (
        <Tag className={`${className || ''} divider-with-text`}
             role={Tag === 'li' && role === 'separator' ? undefined : role} //ul or ol cannot have a direct child with role "separator" so li tag can't have this role
             {...rest}
             data-content={dividerText}/>
    );
};