import React, {useEffect, useRef, useState} from 'react';
import SlickSlider, {CustomArrowProps, Settings} from 'react-slick';
import {SliderLeftArrow} from './SliderLeftArrow';
import {SliderRightArrow} from './SliderRightArrow';
import {IntroData} from './IntroData';
import {SliderData} from './SliderData';
import {IntroTile} from './IntroTile';
import {SliderTile} from './SliderTile';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

type ClassificationAttribute = {
	classification: string;
	sensitiveExtensions: string[];
	locationExtensions: string[];
}

type ClassificationLabelProps = {
	classification?: ClassificationAttribute;
	extraClasses?: string;
}

export type ClassificationLabelConfig = {
	ClassificationLabel?: React.FC<ClassificationLabelProps>;
	getClassificationAttribute: (classificationSummary: any) => any;
}

type SliderProps = {
	slides: SliderData[];
	statusMessage: string;
	svgIconPath: string;
	onItemSubscribeClicked: (isSubscribed: boolean, itemId: number) => void;
	ariaLabel: string;
	autoplay?: boolean;
	autoplaySpeed?: number;
	infiniteSlides?: boolean;
	itemsPerSlide: number;
	loading?: boolean;
	introSlide?: IntroData;
	onSlideClicked?: (itemId: number) => void;
	onSlideEntered?: (itemId: number) => void;
	afterChange?: (currentSlide: number) => void;
	classificationLabelConfig?: ClassificationLabelConfig;
	expiryMessages: {
		expired: string;
		timeLeft: string;
		daysLeft: string;
		hoursLeft: string;
		minutesLeft: string;
	}
}

export type SliderArrowProps = CustomArrowProps & { svgIconPath: string }

type DisplayData = {
	slides: SliderData[];
}

export const IdeascaleSlider = (props: SliderProps) => {
	const {
		ariaLabel,
		autoplay = true,
		autoplaySpeed = 5000,
		infiniteSlides = false,
		itemsPerSlide,
		loading = false,
		introSlide,
		slides,
		onItemSubscribeClicked,
		onSlideClicked,
		onSlideEntered,
		afterChange,
		statusMessage,
		svgIconPath,
		classificationLabelConfig,
		expiryMessages
	} = props;

	const [displaySlides, setDisplaySlides] = useState<DisplayData[]>([]);
	const sliderWrapperRef = useRef<HTMLDivElement>(null);
	const sliderRef = useRef<SlickSlider | null>(null);
	const cardsPerSlide = useRef(itemsPerSlide);

	const pauseSlider = () => {
		if (autoplay) {
			sliderRef.current?.slickPause();
		}
	};

	const playSlider = () => {
		if (autoplay) {
			sliderRef.current?.slickPlay();
		}
	};

	const updateTabIndex = () => {
		if (sliderWrapperRef.current) {
			const allSlides = sliderWrapperRef.current.querySelectorAll('.slick-slide:not(.slick-active)');
			allSlides.forEach(slide => {
				const slideItem = slide.querySelectorAll('.slider-card');
				slideItem.forEach(card => {
					if (card) {
						card.setAttribute('tabIndex', '-1');
					}
				});
			});

			const allActiveSlickSlides = sliderWrapperRef.current.querySelectorAll('.slick-slide.slick-active');
			allActiveSlickSlides.forEach(slide => {
				const slideItem = slide.querySelectorAll('.slider-card');
				slideItem.forEach(card => {
					if (card) {
						card.setAttribute('tabIndex', '0');
					}
				});
			});
		}
	};

	const prepareIndividualSlide = (currentIndex: number, data: SliderData[]): SliderData[] => {
		const slideData: SliderData[] = [];
		if (currentIndex + cardsPerSlide.current <= data.length) {
			const campaignsInSlide = data.slice(currentIndex, currentIndex + cardsPerSlide.current);
			slideData.push(...campaignsInSlide);
		} else if (currentIndex === 0) {
			const campaignsInSlide = data.slice(currentIndex, data.length);
			slideData.push(...campaignsInSlide);
		} else {
			const slidesLeft = data.length - currentIndex;
			const slidesMissing = cardsPerSlide.current - slidesLeft;
			const campaignsInSlide = data.slice(currentIndex - slidesMissing, currentIndex - slidesMissing + data.length);
			slideData.push(...campaignsInSlide);
		}
		return slideData;
	};

	const sliderSettings: Settings = {
		autoplay: autoplay,
		autoplaySpeed: autoplaySpeed,
		accessibility: true,
		className: 'mx-n2 row',
		pauseOnFocus: true,
		infinite: infiniteSlides,
		slidesToShow: 1,
		slidesToScroll: 1,
		arrows: true,
		prevArrow: <SliderLeftArrow svgIconPath={svgIconPath}/>,
		nextArrow: <SliderRightArrow svgIconPath={svgIconPath}/>,
		onInit: () => updateTabIndex(),
		onReInit: () => updateTabIndex(),
		afterChange: afterChange
	};

	useEffect(() => {
		if (window.innerWidth < 992) {
			cardsPerSlide.current = 1;
		}
		let i = 0;
		const displayArray: DisplayData[] = [];
		while (i < slides.length) {
			displayArray.push({slides: prepareIndividualSlide(i, slides)});
			i += cardsPerSlide.current;
		}
		setDisplaySlides(displayArray);
	}, [slides]);

	return (
		<div className="position-relative"
			 ref={sliderWrapperRef}
			 aria-label={ariaLabel}
			 role="group">
			{
				loading ? (
					<div className="d-flex flex-row justify-content-between slick-list">
						<div className="slider-skeleton mx-2"/>
					</div>
				) : (
					<SlickSlider {...sliderSettings} className="ideascale-slider px-1 mx-n1" ref={sliderRef}>
						{
							introSlide &&
							<IntroTile sliderData={introSlide}/>
						}
						{
							displaySlides.map((data, index) =>
								<SliderTile key={index}
											playSlides={playSlider}
											pauseSlides={pauseSlider}
											sliderData={data.slides}
											svgIconPath={svgIconPath}
											onSubscribeClicked={onItemSubscribeClicked}
											onSlideClicked={onSlideClicked}
											onSlideEntered={onSlideEntered} statusMessage={statusMessage}
											classificationLabelConfig={classificationLabelConfig}
											expiryMessages={expiryMessages}/>)
						}
					</SlickSlider>
				)
			}
		</div>
	);
};