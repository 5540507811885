import React, {useState} from 'react';
import {Collapse} from 'reactstrap';
import {Backdrop} from './Backdrop';
import {SidebarItem} from './SidebarItem';
import {Icon} from '../Icon';
import {Scrollbar} from '../Scrollbar';

export type SidebarMenuItem = {
	title: string,
	target: string,
	icon?: string,
	items?: SidebarMenuItem[]
}

type SidebarProps = {
	isOpen?: boolean;
	onToggleSidebar?: () => void;
	items: SidebarMenuItem[];
	depth?: number;
	defaultOpenMenu: string;
	svgIconPath: string;
	searchTerm: string,
	placeholder?: string,
	search: (searchTerm: string) => void,
	checkIfRootMatchesRoute?: (root: SidebarMenuItem) => void,
}

export const Sidebar = (props: SidebarProps) => {
	const {
		isOpen,
		onToggleSidebar,
		items,
		depth = 0,
		defaultOpenMenu,
		svgIconPath,
		searchTerm,
		search,
		checkIfRootMatchesRoute,
		placeholder = 'Search...'
	} = props;
	const [sideNavExpand, setSideNavExpand] = useState(true);

	const toggleSideNav = () => {
		setSideNavExpand(!sideNavExpand);
	};

	const addSideNavClass = () => {
		setSideNavExpand(true);
	};

	return (
		<aside className={`side-nav ${isOpen ? 'sidebar-opened' : ''} ${sideNavExpand ? '' : 'collapsed-nav'}`}>
			<Collapse navbar isOpen={isOpen} timeout={0}
					  className={`drawer-able d-lg-block h-100 ${sideNavExpand ? 'side-nav-expand' : 'side-nav-collapsed'}`}>
				<Backdrop clicked={onToggleSidebar}/>
				<div className="left-panel h-100">
					<div className="list-group fw-bold border-radius-0 h-100">
						<div className="search-group m-3 mb-lg-1">
							<input className="form-control text-start search-group-input"
								   type="text" name="searchKey" value={searchTerm}
								   onChange={(e) => search(e.target.value)}
								   placeholder={placeholder}/>
							<button type="submit">
                                        <span role="presentation">
                                            <Icon name="magnifying-glass-left" fill="#696a6e" iconSpritePath={svgIconPath}/>
                                        </span>
								<span className="sr-only">Search</span>
							</button>
						</div>
						<div className="collapsed-search-icon mx-auto cursor-pointer" onClick={toggleSideNav}>
							<Icon name="magnifying-glass-left" fill="#696a6e" iconSpritePath={svgIconPath}/>
						</div>
						<nav onClick={addSideNavClass}>
							<Scrollbar autoHeight autoHeightMax={700} autoHide autoHideTimeout={500}>
								<div className="is-sidebar">
									{
										items.map((item, index) => {
											return (
												<SidebarItem
													svgIconPath={svgIconPath}
													key={index}
													defaultOpenMenu={defaultOpenMenu}
													searchTerm={searchTerm}
													checkIfRootMatchesRoute={checkIfRootMatchesRoute}
													depth={depth} items={item}/>);
										})
									}
								</div>
							</Scrollbar>
						</nav>
						<div className="side-nav-footer">
                            <span className="cursor-pointer" onClick={toggleSideNav}>
                                <Icon name={`${sideNavExpand ? 'chevron-left-solid-circle' : 'chevron-right-solid-circle'}`}
									  iconSpritePath={svgIconPath} width={20} height={20}/>
                            </span>
						</div>
					</div>
				</div>
			</Collapse>
		</aside>
	);
};
