import React, {memo} from 'react';
import {Skeleton} from './Skeleton';

type TableSkeletonProps = {
    rowCount?: number;
    columnCount?: number;
}

export const TableSkeleton = memo((props: TableSkeletonProps) => {
    const {rowCount = 1, columnCount = 1} = props;

    const skeleton = [];
    for (let row = 0; row < rowCount; row++) {
        const columns = [];
        for (let column = 0; column < columnCount; column++) {
            columns[column] = <td className="td" key={column}><Skeleton/></td>;
        }
        skeleton[row] = <tr className="tr" key={row}>{columns}</tr>;
    }

    return (
        <table className="table table-hover">
            <thead>
            {skeleton[0]}
            </thead>
            <tbody>
            {skeleton}
            </tbody>
        </table>
    );
});