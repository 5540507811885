import React from 'react';
import {SliderData} from './SliderData';
import {Icon} from '../Icon';
import {ExpiryMessage} from '../ExpiryMessage';

type ClassificationAttribute = {
	classification: string;
	sensitiveExtensions: string[];
	locationExtensions: string[];
}

type ClassificationLabelProps = {
	classification?: ClassificationAttribute;
	extraClasses?: string;
}

type CampaignTileProps = {
	campaignData: SliderData;
	statusMessage: string;
	svgIconPath: string;
	onSubscribeClicked?: (isSubscribed: boolean, itemId: number) => void;
	onSlideClicked?: (itemId: number) => void;
	onSlideEntered?: (itemId: number) => void;
	classificationLabelConfig?: ClassificationLabelConfig;
	playSlides: () => void;
	pauseSlides: () => void;
	expiryMessages: {
		expired: string;
		timeLeft: string;
		daysLeft: string;
		hoursLeft: string;
		minutesLeft: string;
	}
}
export type ClassificationLabelConfig = {
	ClassificationLabel?: React.FC<ClassificationLabelProps>;
	getClassificationAttribute: (classificationSummary: any) => any;
}

export const SliderCard = (props: CampaignTileProps) => {
	const {
		campaignData: {
			id,
			title,
			content,
			ariaLabel,
			status,
			subscribed,
			image,
			altText,
			classificationSummary,
			expiryDate,
		},
		onSubscribeClicked,
		onSlideClicked,
		onSlideEntered,
		statusMessage,
		svgIconPath,
		classificationLabelConfig,
		expiryMessages,
		playSlides,
		pauseSlides
	} = props;
	const {ClassificationLabel, getClassificationAttribute} = classificationLabelConfig || {};

	const onCardKeyDown = (e: React.KeyboardEvent) => {
		if (onSlideEntered && e.key === 'Enter') {
			onSlideEntered(id);
		}
	};

	const onCardClicked = () => {
		onSlideClicked && onSlideClicked(id);
	};

	const subscribeIconClicked = (e: React.MouseEvent) => {
		e.stopPropagation();
		onSubscribeClicked && onSubscribeClicked(subscribed, id);
	};

	return (
		<div className="w-100 slider-card py-1 cursor-pointer"
			 onClick={onCardClicked}
			 onKeyDown={onCardKeyDown}
			 aria-label={ariaLabel}
			 lang="en"
			 role="group"
			 tabIndex={0}
			 onFocus={pauseSlides}
			 onBlur={playSlides}>
            <span className="mx-2 position-relative slider-container">
                <div className="slider-bg-overlay w-100 h-100" role="presentation"/>
				{
					image &&
					<img src={image} className="w-100 h-100 slider-image" alt={altText || 'Featured-Image'}/>
				}
				<div
					className={`slider-content d-flex flex-column align-items-start mx-4 py-3 h-100 ${getClassificationAttribute?.(classificationSummary !== undefined) ? 'ps-3' : ''}`}>
					{
						ClassificationLabel ?
							getClassificationAttribute &&
							<ClassificationLabel
								extraClasses="cursor-pointer mb-1"
								classification={getClassificationAttribute(classificationSummary || {})}/>

							: ''
					}
					<div className="d-flex mw-100 ps-2 ms-1 slider-header">
                        <button type="button" className="slider-icon-wrapper" onClick={subscribeIconClicked}
								onKeyDown={e => e.stopPropagation()}
								aria-label="Toggle subscribe" tabIndex={-1} role="switch" aria-checked={subscribed || false}>
                                    <Icon className="slider-icon" iconSpritePath={svgIconPath}
										  name={subscribed ? 'star-solid' : 'star-outlined'} width={20}
										  height={20} fill={'#fff'}/>
                        </button>
							<p className="text-break slider-title ms-1 line-clamp-1"><span>{title}</span></p>
						{status === 'New' ? (
							<span className="badge bg-primary mt-1 ms-2 px-2 h-100">
                             {statusMessage}
                            </span>
						) : null}
                    </div>
					<p className={`my-2 mx-3 text-break ${getClassificationAttribute?.(classificationSummary !== undefined) || expiryDate ? 'line-clamp-3' : 'line-clamp-5'}`}>{content}</p>
					<div className="mt-auto ps-2">
						{
							expiryDate &&
							<ExpiryMessage expiryDate={expiryDate}
										   svgIconsPath={svgIconPath}
										   messages={expiryMessages}/>
						}
					</div>
				</div>
            </span>
		</div>
	);
};