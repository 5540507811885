import {RefObject, useEffect} from 'react';

export const useOnClickOutside = (ref: RefObject<HTMLElement>, handler: (event: MouseEvent | TouchEvent) => void) => {
  useEffect(
    () => {
      const listener = (event: MouseEvent | TouchEvent) => {
        if (event && event.target) {
          if (!ref.current || ref.current.contains((event.target as Node))) {
            return;
          }
        }

        handler(event);
      };

      document.addEventListener('mousedown', listener);
      document.addEventListener('touchstart', listener);

      return () => {
        document.removeEventListener('mousedown', listener);
        document.removeEventListener('touchstart', listener);
      };
    },
    [ref, handler]
  );
};
