import React, {Fragment, KeyboardEventHandler, useState} from 'react';
import {GetOptionLabel, InputActionMeta, OnChangeValue, OptionsOrGroups} from 'react-select';
import Creatable from 'react-select/creatable';
import {OptionType} from './OptionType';
import {
    ClearIndicator,
    DropdownIndicator,
    IdeascaleTag,
    IndicatorSeparator,
    Menu,
    NoOptionsMessage,
    Option
} from './IdeascaleSelectComponents';

interface IdeascaleCreatableSelectProps {
    options?: OptionsOrGroups<OptionType, any>,
    value?: OnChangeValue<OptionType, boolean>;
    onCreateOption?: (inputValue: string) => void;
    defaultOptions?: boolean;
    inputValue?: string;
    defaultValue?: OnChangeValue<OptionType, boolean>;
    name?: string;
    onChange?: (value: OnChangeValue<OptionType, boolean>) => void;
    onInputChange?: (newValue: string, actionMeta: InputActionMeta) => void;
    onKeyDown?: KeyboardEventHandler<HTMLDivElement>;
    onBlur?: () => void;
    onFocus?: () => void;
    onMenuOpen?: () => void;
    onMenuClose?: () => void;
    isMulti?: boolean;
    isClearable?: boolean;
    autoFocus?: boolean;
    createLabel?: string;
    placeholder?: string;
    inputId?: string;
    disabled?: boolean;
    closeMenuOnSelect?: boolean;
    menuIsOpen?: boolean;
    getOptionLabel?: GetOptionLabel<OptionType>;
    showDropdownIndicator?: boolean;
    defaultMenuIsOpen?: boolean;
    noOptionsMessage?: ((obj: { inputValue: string }) => React.ReactNode)
}

export const IdeascaleCreatableSelect = (props: IdeascaleCreatableSelectProps) => {
    const [dialogMarker, setDialogMarker] = useState(false);

    const formatCreateLabel = (value: string) => {
        return <Fragment><strong>{props.createLabel ? props.createLabel : 'Create'}: </strong> {value}</Fragment>;
    };

    const resolveAccessibleLabel = (option: OptionType) => {
        return props.getOptionLabel ? props.getOptionLabel(option) : option.accessibleLabel || option.label;
    };

    return (
        <Creatable
            {...props}
            onMenuOpen={() => {
                setDialogMarker(true);
                props.onMenuOpen?.();
            }}
            onMenuClose={() => {
                props.onMenuClose?.();
                setTimeout(() => {
                    setDialogMarker(false);
                }, 500);
            }}
            getOptionLabel={resolveAccessibleLabel}
            isDisabled={props.disabled}
            className={`ideascale-select ${dialogMarker ? 'additional-dialog-open' : ''}`}
            formatCreateLabel={formatCreateLabel}
            components={{
                Option,
                Menu,
                MultiValue: IdeascaleTag,
                DropdownIndicator: props.showDropdownIndicator ? DropdownIndicator : undefined,
                ClearIndicator,
                IndicatorSeparator,
                NoOptionsMessage
            }}
        />
    );
};
