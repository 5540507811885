import {DateTime} from 'luxon';

export class TimeUtil {
	static currentTimeInMilliSecond(): number {
		return new Date().getTime();
	}

	static isBefore(endDate: string) {
		const difference = DateTime.fromISO(endDate).toMillis() - TimeUtil.currentTimeInMilliSecond();
		return difference <= 0;
	}

	static calculateTimeLeft(endDate: string) {
		const difference = DateTime.fromISO(endDate).toMillis() - TimeUtil.currentTimeInMilliSecond();
		let timeLeft = {
			days: 0,
			hours: 0,
			minutes: 0,
			seconds: 0
		};

		if (difference > 0) {
			timeLeft = {
				days: Math.floor(difference / (1000 * 60 * 60 * 24)),
				hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
				minutes: Math.floor((difference / 1000 / 60) % 60),
				seconds: Math.floor((difference / 1000) % 60)
			};
		}
		return timeLeft;
	};
}