import React from 'react';
import {Icon} from "../Icon";
import {SliderArrowProps} from "./IdeascaleSlider";

export const SliderRightArrow = (props: SliderArrowProps) => {
    const {onClick, svgIconPath} = props;
    return (
        <button className="slider-arrows slider-arrows-next" onClick={onClick}>
            <Icon iconSpritePath={svgIconPath} name="chevron-right" width={30}
                  height={30} fill={'#fff'}/>
            <span className="sr-only">Next</span>
        </button>
    );
};