import React, {Fragment, memo} from 'react';
import {Skeleton} from './Skeleton';

type ListSkeletonProps = {
    items?: number;
    cssClass?: string;
}

export const ListSkeleton = memo((props: ListSkeletonProps) => {
    const {items = 1, cssClass = 'px-4'} = props;

    const skeletons = [];
    for (let i = 0; i < items; i++) {
        skeletons[i] = (
            <div key={i} className={`w-100 ${cssClass}`}>
                <Skeleton width={`${Math.cos(Math.random()) * 100}%`}/>
            </div>
        );
    }

    return (
        <Fragment>
            {skeletons}
        </Fragment>
    );
});
