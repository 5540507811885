import React from 'react';

type SpinnerProps = {
    src: string; // file name should be loading.svg
    size: number;
};

export const Spinner = (props: SpinnerProps) => {
    return (
        <img src={props.src} width={props.size} height={props.size} alt="Loading...." />
    )
};