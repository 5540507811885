import React, {ChangeEventHandler, FocusEventHandler, forwardRef} from 'react';

type CheckboxProps = {
	inputId: string;
	name?: string;
	label?: JSX.Element | string;
	// react-hook-form will always support string type value, but not always number
	value?: string;
	className?: string;
	defaultChecked?: boolean;
	checked?: boolean;
	disabled?: boolean;
	title?: string;
	onChange?: ChangeEventHandler<HTMLInputElement>
	onBlur?: FocusEventHandler<HTMLInputElement>;
}

export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>((props: CheckboxProps, ref) => {
	const {label, className, inputId, ...rest} = props;
	return (
		<div className={`ideascale-custom-checkbox ${className ? className : ''}`}>
			<input className="custom-control-input" type="checkbox" id={inputId} ref={ref} {...rest}/>
			<label className="form-label" htmlFor={inputId}>
				<span className="check-mark"/>
				{label}
			</label>
		</div>

	);
});