import React, {useCallback, useState} from 'react';
import ReactDatePicker, {ReactDatePickerCustomHeaderProps} from 'react-datepicker';
import {isArray} from 'lodash';
import {Button} from './Button';
import {Icon} from './Icon';
import 'react-datepicker/dist/react-datepicker.css';

const MONTHS = [
	'January',
	'February',
	'March',
	'April',
	'May',
	'June',
	'July',
	'August',
	'September',
	'October',
	'November',
	'December',
];

type DatePickerProps = {
	svgIconsPath: string;
	prevMonthLabel: string;
	nextMonthLabel: string;
	prevYearLabel: string;
	nextYearLabel: string;
	onChange: (dates: Date[], rangeSelected?: boolean) => void;
	id?: string;
	className?: string;
	wrapperClassName?: string;
	placeholderText?: string;
	selected?: Date | null;
	dateFormat?: string | string[];
	monthsShown?: number;
	startDate?: Date | null;
	endDate?: Date | null;
	minDate?: Date | null;
	maxDate?: Date | null;
	autoFocus?: boolean;
	inline?: boolean;
	value?: string;
	name?: string;
	openToDate?: Date;
	testId?: string;
	selectsRange?: boolean;
}

export const DatePicker = (props: DatePickerProps) => {
	const {
		svgIconsPath,
		testId = '',
		selectsRange = false,
		onChange,
		prevMonthLabel,
		nextMonthLabel,
		prevYearLabel,
		nextYearLabel,
		selected,
		wrapperClassName = '',
		...rest
	} = props;

	const [startDate, setStartDate] = useState<Date | null>(rest.startDate || null);
	const [endDate, setEndDate] = useState<Date | null>(rest.endDate || null);
	const [selectedDate, setSelectedDate] = useState<Date | null>(selected || null);

	const [dialogMarker, setDialogMarker] = useState(false);

	const onChangeDate = useCallback((date: Date | [(Date | null), (Date | null)] | null) => {
		const dates: Date[] = [];
		if (date !== null) {
			if (isArray(date)) {
				const [start, end] = date;
				setStartDate(start);
				setEndDate(end);
				date.forEach(item => {
					if (item !== null) {
						dates.push(item);
					}
				});
			} else {
				setSelectedDate(date);
				dates.push(date);
			}
		} else {
			setSelectedDate(null);
		}
		onChange(dates, selectsRange);
	}, []);

	const renderHeader = useCallback((params: ReactDatePickerCustomHeaderProps) => {
		const {
			monthDate,
			increaseMonth,
			decreaseMonth,
			nextMonthButtonDisabled,
			prevMonthButtonDisabled,
			increaseYear,
			decreaseYear,
			nextYearButtonDisabled,
			prevYearButtonDisabled
		} = params;

		return (
			<div className="react-datepicker-custom-header d-flex justify-content-center align-items-center">
				<Button color="link"
						className="prev-month-btn p-0 line-height-0 d-none"
						onClick={decreaseMonth}
						disabled={prevMonthButtonDisabled}
						aria-label={prevMonthLabel}>
					<Icon iconSpritePath={svgIconsPath} name="chevron-left" height={20} width={20}/>
				</Button>
				<div className="d-flex flex-grow-1 flex-shrink-1 justify-content-center align-items-center">
					<div className="text-default font-size-lg me-2">{MONTHS[monthDate.getMonth()]}</div>
					<div className="text-default font-size-lg d-flex align-items-center">
						{monthDate.getFullYear()}
						<div className="d-flex flex-column align-items-center justify-content-center ms-1">
							<Button color="link"
									className="p-0 line-height-0"
									onClick={increaseYear}
									disabled={nextYearButtonDisabled}
									aria-label={nextYearLabel}>
								<Icon iconSpritePath={svgIconsPath} name="triangle-up" height={10} width={10}/>
							</Button>
							<Button color="link"
									className="p-0 line-height-0"
									onClick={decreaseYear}
									disabled={prevYearButtonDisabled}
									aria-label={prevYearLabel}>
								<Icon iconSpritePath={svgIconsPath} name="triangle-down" height={10} width={10}/>
							</Button>
						</div>
					</div>
				</div>
				<Button color="link"
						className="next-month-btn p-0 line-height-0 d-none"
						onClick={increaseMonth}
						disabled={nextMonthButtonDisabled}
						aria-label={nextMonthLabel}>
					<Icon iconSpritePath={svgIconsPath} name="chevron-right" height={20} width={20}/>
				</Button>
			</div>
		);
	}, []);

	return (
		<ReactDatePicker
			wrapperClassName={`${wrapperClassName} ${dialogMarker ? 'additional-dialog-open' : ''}`}
			renderCustomHeader={(params) => renderHeader(params)}
			onCalendarOpen={() => setDialogMarker(true)}
			onCalendarClose={() => {
				setTimeout(() => {
					setDialogMarker(false);
				}, 500);
			}}
			calendarClassName="d-flex"
			popperClassName="date-picker-popper"
			className={`react-datepicker ${rest.className}`}
			data-test-element-id={testId}
			selectsRange={selectsRange}
			onChange={onChangeDate}
			onChangeRaw={e => e.preventDefault()}
			startDate={startDate}
			endDate={endDate}
			selected={selectedDate}
			autoComplete="off"
			showPopperArrow={false}
			{...rest}
		/>
	);
};