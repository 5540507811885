import React from 'react';
import {Icon} from './Icon';

type StepperItemType = {
	label: string;
	active: boolean;
	complete: boolean;
	info?: string;
}

type StepperType = {
	className?: string;
	items: StepperItemType[];
	alignment: 'center' | 'left';
	svgIconPath: string;
}

export const Stepper = (props: StepperType) => {
	const {className = '', items, svgIconPath, alignment = 'center'} = props;

	return (
		<ol className={`stepper d-flex flex-wrap ps-0 ${className}`}>
			{
				items.map((item, index) => (
					<li className={`stepper-item d-flex flex-column ${alignment === 'center' ? 'connector-centered align-items-center text-center' : 'align-items-start'}  ${item.active ? 'step-active' : ''} ${item.complete ? 'step-complete' : ''}`}
						key={index} aria-current={item.active ? 'step' : 'false'}>
						<span className="sr-only" aria-live={item.active ? 'polite' : 'off'}>{item.label}</span>
						<div className="step-indicator mb-2 text-center">
							{
								item.complete &&
								<Icon iconSpritePath={svgIconPath} name="check" height={16} width={16}/>
							}
						</div>
						<span className={`step-label mb-1 ${item.complete || item.active ? 'fw-semibold' : ''}`}>
                            {item.label}
                        </span>
						<span className="step-info">
                            {item.info}
                        </span>
					</li>
				))
			}
		</ol>
	);
};