import React from 'react';
import {
	UncontrolledButtonDropdown as BootstrapUncontrolledButtonDropdown,
	UncontrolledButtonDropdownProps
} from 'reactstrap';

export const UncontrolledButtonDropdown = (props: UncontrolledButtonDropdownProps) => {
	const {children, a11y = false, ...rest} = props;

	return <BootstrapUncontrolledButtonDropdown a11y={a11y} {...rest}>{children}</BootstrapUncontrolledButtonDropdown>;

};