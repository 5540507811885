import React, {memo} from 'react';
import {Skeleton} from './Skeleton';

type ParagraphSkeletonProps = {
    rows?: number;
}

export const ParagraphSkeleton = memo((props: ParagraphSkeletonProps) => {
    const {rows = 4} = props;

    const skeletons: JSX.Element[] = [];
    for (let i = 0; i < rows; i++) {
        skeletons[i] = <Skeleton key={i} width={`${Math.cos(Math.random()) * 100}%`}/>;
    }

    return (
        <div className="paragraph-skeleton">
            {skeletons}
        </div>
    )
});