import React from 'react';
import RangeSlider from 'rc-slider';

type ReviewScaleRatingProps = {
	value?: number;
	defaultValue?: number;
	min?: number;
	max?: number;
	step?: number | null;
	vertical?: boolean;
	included?: boolean;
	disabled?: boolean;
	reverse?: boolean;
	onChange?: (value: number) => void;
	onBeforeChange?: (value: number) => void;
	onAfterChange?: (value: number) => void;
	autoFocus?: boolean;
	onFocus?: (e: React.FocusEvent<HTMLDivElement>) => void;
	onBlur?: (e: React.FocusEvent<HTMLDivElement>) => void;
	className?: string;
	marks?: Record<number, React.ReactNode | {
		style?: React.CSSProperties;
		label?: string;
	}>;
	activeDotStyle?: React.CSSProperties;
	draggableTrack?: boolean;
	ariaLabelForHandle?: string;
	handle?: (props: {
		className: string;
		prefixCls?: string;
		vertical?: boolean;
		offset: number;
		value: number;
		dragging?: boolean;
		disabled?: boolean;
		min?: number;
		max?: number;
		reverse?: boolean;
		index: number;
		tabIndex?: number;
		ariaLabel: string;
		ariaLabelledBy: string;
		ariaValueTextFormatter?: (value: number) => string;
		style?: React.CSSProperties;
		ref?: React.Ref<any>;
	}) => React.ReactElement;
}

export const ReviewScaleRating = (props: ReviewScaleRatingProps) => {
	return (
		<div className="reviewscale-rating-range">
			<RangeSlider {...props} />
		</div>
	);
};
