import React from 'react';

export const AppLoading = () => {
	return (
		<div className="app-loading">
			<svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="84.491px" height="84.061px"
				 viewBox="0 0 995.000000 992.000000" preserveAspectRatio="xMidYMid meet">
				<g transform="translate(0.000000,992.000000) scale(0.100000,-0.100000)"
				   fill="#636366" stroke="#636366">
					<path d="M3675 9908 c3 -7 234 -600 513 -1318 l508 -1305 279 0 279 0 506
                        1300 c278 715 509 1308 513 1318 6 16 -11 17 -271 17 l-277 0 -379 -1012
                        c-209 -556 -381 -1007 -383 -1002 -2 5 -172 459 -377 1009 l-374 1000 -271 3
                        c-215 2 -269 0 -266 -10z"/>
					<path d="M2167 9202 c-103 -103 -187 -193 -187 -199 0 -7 194 -439 431 -960
                        237 -521 438 -962 445 -980 l14 -32 -63 28 c-34 16 -478 218 -986 450 l-925
                        422 -190 -190 c-105 -105 -188 -192 -186 -194 11 -8 2587 -1137 2596 -1137 5
                        0 96 87 202 193 l192 192 -569 1295 c-313 712 -573 1296 -578 1297 -4 2 -92
                        -81 -196 -185z"/>
					<path d="M7546 9360 c-8 -19 -265 -604 -570 -1300 l-556 -1264 192 -194 c150
                        -150 197 -192 213 -188 24 6 2565 1123 2574 1131 3 3 -82 93 -189 200 l-195
                        194 -979 -445 c-538 -245 -980 -444 -982 -442 -2 2 197 442 441 977 245 536
                        445 977 445 980 0 3 -85 91 -190 196 l-189 189 -15 -34z"/>
					<path d="M0 5972 l0 -277 1010 -379 c556 -208 1009 -380 1008 -381 -2 -2 -453
                        -170 -1003 -375 -550 -205 -1003 -376 -1008 -379 -4 -4 -6 -127 -5 -273 l3
                        -265 1315 512 1315 512 0 278 0 279 -1290 503 c-710 276 -1302 506 -1317 512
                        l-28 10 0 -277z"/>
					<path d="M8630 5736 l-1315 -512 0 -279 0 -278 1305 -509 c718 -279 1311 -510
                        1318 -512 9 -4 12 54 12 272 l0 277 -1010 379 c-556 208 -1009 380 -1008 381
                        2 2 453 171 1003 376 550 204 1003 375 1008 379 4 3 6 126 5 272 l-3 266
                        -1315 -512z"/>
					<path d="M1841 2945 c-706 -310 -1286 -567 -1290 -570 -3 -3 82 -93 189 -200
                        l195 -194 979 445 c538 245 980 444 982 442 2 -2 -197 -442 -441 -977 -245
                        -536 -445 -976 -445 -980 0 -3 85 -91 190 -196 l189 -189 15 34 c8 19 265 604
                        570 1300 l556 1264 -192 193 c-106 106 -197 193 -203 193 -5 0 -588 -254
                        -1294 -565z"/>
					<path d="M6612 3297 l-193 -193 567 -1289 c311 -710 570 -1294 575 -1300 10
                        -10 389 361 389 381 0 6 -200 452 -445 989 -245 538 -444 979 -442 980 1 1
                        445 -200 986 -448 l984 -449 183 183 c101 101 184 189 184 195 0 9 -2528 1128
                        -2578 1140 -12 3 -74 -52 -210 -189z"/>
					<path d="M4697 2633 c-3 -6 -937 -2400 -1018 -2610 l-9 -23 278 0 277 0 379
                        1010 c208 556 380 1009 381 1008 2 -2 170 -453 375 -1003 205 -550 376 -1003
                        380 -1008 3 -4 126 -6 272 -5 l266 3 -512 1315 -512 1315 -277 3 c-151 1 -278
                        -1 -280 -5z"/>
				</g>
			</svg>

			<svg className="spinner" viewBox="25 25 50 50" xmlns="http://www.w3.org/2000/svg">
				<circle className="path" cx="50" cy="50" r="20" fill="none" strokeWidth="2"
						strokeMiterlimit="10"/>
			</svg>
		</div>
	);
};