import React from 'react';
import {Direction} from 'reactstrap/types/lib/Dropdown';
import {DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown} from './ideascale-dropdown';
import {Avatar} from './Avatar';
import {Backdrop} from './ideascale-sidebar/Backdrop';

type UserPopoverProps = {
	a11y?: boolean;
	members: Array<{ name: string, username: string, url: string, avatar: string }>;
	triggerComponent: React.ReactNode | string;
	triggerClassName?: string;
	triggerColor?: string;
	direction?: Direction;
	ariaLabel: string;
}

export const UncontrolledUserDropdown = (props: UserPopoverProps) => {
	const {
		a11y = false,
		members,
		triggerComponent,
		triggerClassName,
		direction = 'end',
		triggerColor = 'secondary',
		ariaLabel = ''
	} = props;
	return (
		<UncontrolledDropdown a11y={a11y} className="drawer-able d-inline" direction={direction}>
			<DropdownToggle className={triggerClassName} color={triggerColor} aria-label={ariaLabel}>
				<span className="pointer-events-none">{triggerComponent}</span>
			</DropdownToggle>
			<Backdrop/>
			<div className="left-panel d-inline">
				<DropdownMenu className="member-list-dropdown">
					{
						members.map((member, index) =>
							<DropdownItem className="member-item d-flex" tag="a" href={member.url} key={index}
										  target="_blank" rel="noopener noreferrer">
								<div className="member-avatar-container">
									<Avatar src={member.avatar} size="sm" alt=""/>
								</div>
								<div className="member-list-item-content">
									<strong>{member.name}</strong>
									{member.username && <small>@{member.username}</small>}
								</div>
							</DropdownItem>
						)
					}
				</DropdownMenu>
			</div>
		</UncontrolledDropdown>
	);
};