import React from 'react';
import {SliderData} from './SliderData';
import {ClassificationLabelConfig, SliderCard} from './SliderCard';

type SliderTileProps = {
	sliderData: SliderData[];
	statusMessage: string;
	svgIconPath: string;
	onSubscribeClicked?: (isSubscribed: boolean, itemId: number) => void
	onSlideClicked?: (itemId: number) => void;
	onSlideEntered?: (itemId: number) => void;
	classificationLabelConfig?: ClassificationLabelConfig;
	expiryMessages: {
		expired: string;
		timeLeft: string;
		daysLeft: string;
		hoursLeft: string;
		minutesLeft: string;
	},
	playSlides: () => void;
	pauseSlides: () => void;
}

export const SliderTile = (props: SliderTileProps) => {
	const {
		sliderData,
		onSubscribeClicked,
		onSlideClicked,
		onSlideEntered,
		statusMessage,
		svgIconPath,
		classificationLabelConfig,
		expiryMessages,
		playSlides,
		pauseSlides
	} = props;

	return (
		<div className="d-flex">
			{
				sliderData.map(data => <SliderCard key={data.id}
												   playSlides={playSlides}
												   pauseSlides={pauseSlides}
												   campaignData={data}
												   onSubscribeClicked={onSubscribeClicked}
												   onSlideClicked={onSlideClicked}
												   onSlideEntered={onSlideEntered}
												   statusMessage={statusMessage}
												   svgIconPath={svgIconPath}
												   classificationLabelConfig={classificationLabelConfig}
												   expiryMessages={expiryMessages}/>)
			}
		</div>
	);
};