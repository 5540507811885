import React from 'react';

type propsType = {
	label: string;
	className?: string;
	href?: string;
	badge?: string | number;
	deletable?: boolean;
	onDelete?: () => void;
	elementId?: string;
}
export const Tag = (props: propsType) => {
	const {
		label,
		className = '',
		href = '',
		badge = '',
		deletable = false,
		onDelete = () => {
			return;
		},
		elementId = ''
	} = props;

	const tagBody =
		<React.Fragment>
			<span className="tag-label">{label}</span>
			{deletable && <button type="button" className="close" aria-label="Close" onClick={onDelete}
								  data-test-element-id="close-tag">
				<span aria-hidden="true">&times;</span>
			</button>}
			<span className="badge tag-badge">{badge}</span>
		</React.Fragment>;

	return (
		<React.Fragment>
			{
				href
					? <a className={`${className} tag${(badge) ? ` with-badge` : ``}`} href={href}
						 data-test-element-id={elementId}>{tagBody}</a>
					: <span className={`${className} tag${(badge) ? ` with-badge` : ``}`}
							data-test-element-id={elementId}>{tagBody}</span>
			}
		</React.Fragment>
	);
};
