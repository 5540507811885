import React from 'react';
import {Link} from 'react-router-dom';
import {PopoverBody, UncontrolledPopover} from 'reactstrap';
import type {Boundary, Placement} from '@popperjs/core';
import {Avatar} from './Avatar';

type UserPopoverProps = {
	selector: string;
	members: { id: number, name: string, username: string, avatar: string, url?: string, identityHidden?: boolean }[];
	boundariesElement?: Boundary | Element;
	placement?: Placement;
	reactRouterLink?: boolean;
	target?: string;
}

export const UncontrolledUserPopover = (props: UserPopoverProps) => {
	const {
		members,
		selector,
		boundariesElement = 'clippingParents',
		placement = 'right',
		reactRouterLink = false,
		target = '_self'
	} = props;

	return (
		<UncontrolledPopover className="member-list-popover"
							 placement={placement}
							 trigger="legacy"
							 boundariesElement={boundariesElement}
							 target={selector}>
			<PopoverBody>
				<div className="popover-member-list">
					<ul className="list-unstyled member-list">
						{members.map((member, index) => {
								const {url, id, identityHidden, username, avatar, name} = member;
								return <li key={index}>
									{reactRouterLink ? (
										<Link to={id && url && !identityHidden ? url : '#'}
											  className={`${id && url && !identityHidden ? '' : 'text-gray cursor-default'}`}>
											<div className="member-avatar-container">
												<Avatar src={avatar} size="sm" alt="member-avatar"/>
											</div>
											<div className="member-list-item-content">
												<strong>{name}</strong>
												{username && <small>@{username}</small>}
											</div>
										</Link>
									) : (
										<a href={id && url && !identityHidden ? url : '#'}
										   target={target}
										   rel="noopener noreferrer"
										   className={`${id && url && !identityHidden ? '' : 'text-gray cursor-default'}`}>
											<div className="member-avatar-container">
												<Avatar src={avatar} size="sm" alt="member-avatar"/>
											</div>
											<div className="member-list-item-content">
												<strong>{name}</strong>
												{username && <small>@{username}</small>}
											</div>
										</a>
									)}
								</li>;
							}
						)}
					</ul>
				</div>
			</PopoverBody>
		</UncontrolledPopover>
	);
};