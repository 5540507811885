import React, {ChangeEventHandler, FocusEventHandler, forwardRef, useEffect, useImperativeHandle, useRef} from 'react';

type MultiChoiceProps = {
	label: string;
	name: string;
	title?: string;
	value?: string; // react-hook-form will always support string type value, but not always number
	isActive?: boolean;
	checked?: boolean;
	inputId?: string;
	defaultChecked?: boolean;
	disabled?: boolean;
	onChange?: ChangeEventHandler<HTMLInputElement>
	onBlur?: FocusEventHandler<HTMLInputElement>;
}

export const MultiChoice = forwardRef<HTMLInputElement, MultiChoiceProps>((props, forwardRef) => {
	const {label, title, inputId, isActive, checked, disabled, ...rest} = props;
	const inputRef = useRef<HTMLInputElement>(null);

	useImperativeHandle<HTMLInputElement | null, HTMLInputElement | null>(forwardRef, () => inputRef.current);

	useEffect(() => {
		// This is to keep react state and DOM state in sync
		if (typeof checked === 'boolean' && inputRef.current) {
			inputRef.current.checked = checked;
		}
	}, [checked]);

	return (
		<label className={`btn btn-tag${disabled ? ' disabled' : ''}${(isActive || checked) ? ' active' : ''}`}
			   title={title}>
			<input type="checkbox" id={inputId} ref={inputRef} disabled={disabled} checked={checked} {...rest}/>
			<span className="check-mark"/>
			{label}
		</label>
	);
});