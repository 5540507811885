import {parseGIF, decompressFrames} from 'gifuct-js';
import GIF from 'gif.js';

type OptionType = {
	background: string;
	maxWidth: number;
	maxHeight: number;
	src: string;
	onerror: (errorCode: string, error?: Error) => void;
	encoder: {
		workers: number,
		quality: number,
		width?: number,
		height?: number,
		workerScript?: string,
	}
}

const ERROR = {
	IMAGE_LOAD_ERROR: "IMAGE_LOAD_ERROR",
	IMAGE_READ_ERROR: "IMAGE_READ_ERROR",
	DECODE_ERROR: "DECODE_ERROR",
	ENCODE_ERROR: "ENCODE_ERROR"
}

const nextTick = window.requestAnimationFrame || (window as any).webkitRequestAnimationFrame || window.setTimeout;

class GifCropper {
	options: OptionType;
	containerCanvas: HTMLCanvasElement;
	containerCtx:  CanvasRenderingContext2D | null;
	convertorCanvas: HTMLCanvasElement;
	convertorCtx:  CanvasRenderingContext2D | null;
	containerCenterX: number;
	containerCenterY: number;
	image: HTMLImageElement | null;
	height: number;
	width: number;
	frames: any;
	offsetX: number;
	offsetY: number;

	constructor(options: OptionType) {
		options.background = options.background || 'fff';
		this.options = options;
		this.containerCanvas = document.createElement('canvas');
		this.containerCtx = this.containerCanvas.getContext('2d');
		this.convertorCanvas = document.createElement('canvas');
		this.convertorCtx = this.convertorCanvas.getContext('2d');
		this.containerCenterX = 0;
		this.containerCenterY = 0;
		this.image = null;
		this.height = 0;
		this.width = 0;
		this.offsetX = 0;
		this.offsetY = 0;
	}

	public crop = (cropper: Cropper, callback: (cropBlob: Blob) => void) => {
		const cropArea = cropper.getData();
		const limitRatio = this.calcLimitRatio(cropArea);
		const limitCropArea = {
			x: Math.round(cropArea.x * limitRatio),
			y: Math.round(cropArea.y * limitRatio),
			width: Math.round(cropArea.width * limitRatio),
			height: Math.round(cropArea.height * limitRatio),
			scaleX: cropArea.scaleX * limitRatio,
			scaleY: cropArea.scaleY * limitRatio,
			rotate: cropArea.rotate
		};

		this.readAndDecodeGif(() => {
			this.setupCanvas(limitCropArea, limitRatio);
			this.cropFrame(0, limitCropArea, [], (result) => {
				this.saveGif(limitCropArea, result, (cropBlob) => {
					callback && callback(cropBlob);
				});
			});
		});
	}

	calcLimitRatio = (cropArea: Cropper.Data) => {
		const xRatio = this.options.maxWidth / cropArea.width;
		const yRatio = this.options.maxHeight / cropArea.height;
		if(xRatio < 1 || yRatio < 1) {
			return Math.min(xRatio, yRatio);
		}
		return 1;
	};

	readAndDecodeGif = (callback: () => void) => {
		this.image = new Image();
		this.image.onload = (e) => {
			let image = e.currentTarget as HTMLImageElement;
			this.width = image.naturalWidth || image.width;
			this.height = image.naturalHeight || image.height;

			const xhr = new XMLHttpRequest();
			xhr.responseType = "arraybuffer";
			xhr.onreadystatechange = () => {
				if(xhr.readyState === 4) {
					if(xhr.status === 200) {
						this.decode(xhr.response, callback);
					} else {
						this.errorHandler(ERROR.IMAGE_READ_ERROR, new Error(xhr.statusText));
					}
				}
			}
			xhr.open('GET', this.options.src);
			xhr.send(null);
		}
		this.image.onerror = () => {
			this.errorHandler(ERROR.IMAGE_LOAD_ERROR);
		}
		this.image.src = this.options.src;
	}

	decode = (arraybuffer: ArrayBuffer, callback: () => void) => {
		try {
			const parsedGif = parseGIF(arraybuffer);
			this.frames = decompressFrames(parsedGif, false);
			callback && callback();
		} catch (error: any) {
			this.errorHandler(ERROR.DECODE_ERROR, error);
		}
	}

	setupCanvas = (cropArea: Cropper.Data, limitRatio:number) => {
		const radian = Math.PI/180*cropArea.rotate;
		const rotatedBoxWidth = (this.width*Math.cos(radian)+this.height*Math.sin(radian)) * limitRatio;
		const rotatedBoxHeight = (this.height*Math.cos(radian)+this.width*Math.sin(radian)) * limitRatio;

		this.offsetX = -Math.min(cropArea.x, 0);
		this.offsetY = -Math.min(cropArea.y, 0);
		this.containerCenterX = this.offsetX + rotatedBoxWidth / 2;
		this.containerCenterY = this.offsetY + rotatedBoxHeight / 2;

		this.containerCanvas.width = Math.max(this.offsetX + rotatedBoxWidth, this.offsetX + cropArea.width, cropArea.x + cropArea.width);
		this.containerCanvas.height = Math.max(this.offsetY + rotatedBoxHeight, this.offsetY + cropArea.height, cropArea.y + cropArea.height);
		this.containerCtx?.clearRect(0, 0, this.containerCanvas.width, this.containerCanvas.height);

		this.convertorCanvas.width = this.width;
		this.convertorCanvas.height = this.height;
	}

	cropFrame = (frameIndex: number, cropArea: Cropper.Data, result: Array<ImageData>, callback: (result: Array<ImageData>) => void) => {
		const frame = this.frames[frameIndex];
		const imgData = this.frameToImageData(this.containerCtx, frame);
		let cropImgData;
		const that = this;

		this.containerCtx?.save();
		this.containerCtx?.translate(this.containerCenterX, this.containerCenterY);
		this.containerCtx?.rotate(cropArea.rotate*Math.PI/180);
		this.containerCtx?.scale(cropArea.scaleX, cropArea.scaleY);
		this.containerCtx?.drawImage(this.drawImgDataToCanvas(frame, imgData), -this.convertorCanvas.width/2, -this.convertorCanvas.height/2);
		this.containerCtx?.restore();

		if(frameIndex === 0 && this.containerCtx?.globalCompositeOperation) {
			this.containerCtx.fillStyle = this.options.background;
			this.containerCtx.globalCompositeOperation = "destination-over";
			this.containerCtx.fillRect(0, 0, this.containerCanvas.width, this.containerCanvas.height);
			this.containerCtx.globalCompositeOperation = "source-over";
		}

		cropImgData = this.containerCtx?.getImageData(
			cropArea.x + this.offsetX,
			cropArea.y + this.offsetY,
			cropArea.width,
			cropArea.height
		);

		if(cropImgData) {
			result.push(cropImgData);
		}

		frameIndex++;
		if(frameIndex < this.frames.length) {
			nextTick(function(){
				that.cropFrame(frameIndex, cropArea, result, callback);
			});
		} else {
			callback && callback(result);
		}
	}

	drawImgDataToCanvas = (frame: any, imgData: ImageData | undefined) => {
		this.convertorCtx?.clearRect(0,0, this.width, this.height);
		if (imgData) {
			this.convertorCtx?.putImageData(imgData, frame.dims.left, frame.dims.top);
		}
		return this.convertorCanvas;
	}

	saveGif = (cropArea: Cropper.Data, imgDataList: Array<ImageData>, callback: (cropBlob: Blob) => void) => {
		const options = this.options.encoder || {};
		options.width = cropArea.width;
		options.height = cropArea.height;

		try {
			const encoder = new GIF(options);

			for(let i=0;i<imgDataList.length;i++) {
				encoder.addFrame(imgDataList[i], {
					delay: this.frames[i].delay
				});
			}
			encoder.on('finished', (blob: Blob) => {
				callback && callback(blob);
				encoder.abort();
				//@ts-ignore
				const workers = encoder.freeWorkers;
				for (let i = 0; i < workers.length; i++) {
					const worker = workers[i];
					worker.terminate();
				}
			});
			encoder.render();
		} catch (error: any) {
			this.errorHandler(ERROR.DECODE_ERROR, error);
		}
	}

	frameToImageData = (ctx: CanvasRenderingContext2D | null, frame: any) => {
		const totalPixels = frame.pixels.length;
		const imgData = ctx?.createImageData(frame.dims.width, frame.dims.height);
		if (imgData) {
			const patchData = imgData.data;
			for (let i = 0; i < totalPixels; i++) {
				const pos = i * 4;
				const colorIndex = frame.pixels[i];
				const color = frame.colorTable[colorIndex];
				patchData[pos] = color[0];
				patchData[pos + 1] = color[1];
				patchData[pos + 2] = color[2];
				patchData[pos + 3] = colorIndex !== frame.transparentIndex ? 255 : 0;
			}
		}
		return imgData;
	};

	errorHandler = (errorCode: string, error?: Error) => {
		this.options.onerror && this.options.onerror(errorCode, error);
	}
}

export const CropperjsGif = {
	crop: (options: OptionType, cropper: Cropper, callback: (blob: Blob) => void) => new GifCropper(options).crop(cropper, callback)
}