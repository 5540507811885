import React, {forwardRef, Ref} from 'react';
import {ScrollbarProps, Scrollbars} from 'react-custom-scrollbars-2';

export {Scrollbars as ScrollbarInstance} from 'react-custom-scrollbars-2';

export const Scrollbar = forwardRef((props: ScrollbarProps, ref: Ref<Scrollbars>) => {
	return (
		<Scrollbars {...props}
					ref={ref}
					className={`${props.className ? props.className : ''} scrollbar`}
					renderThumbHorizontal={
						props.renderThumbHorizontal ?
							props.renderThumbHorizontal : (horizontalThumbProps: any) => <div {...horizontalThumbProps}/>
					}
					renderThumbVertical={(verticalThumbProps: any) => <div {...verticalThumbProps}
																		   className="thumb-vertical"/>}
					autoHideTimeout={500}
					renderView={(props) => <div tabIndex={-1} {...props}/>}>
			{props.children}
		</Scrollbars>
	);
});