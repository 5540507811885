import React from 'react';
import {IntroData} from './IntroData';
import {Scrollbar} from '../Scrollbar';

type CommunityTileProps = {
    sliderData: IntroData;
}

export const IntroTile = (props: CommunityTileProps) => {
    const {sliderData: {subtitle, altText, title, image, tag: Tag = 'div'}} = props;

    return (
        <div className="mx-2 position-relative slider-container">
            <div className="slider-bg-overlay w-100 h-100" role="presentation"/>
            {
                image &&
                <img src={image} className="w-100 h-100 slider-image" alt={altText}/>
            }
            <div
                className="slider-content d-flex justify-content-center flex-column align-items-center w-100 h-100 overflow-hidden">
                <Tag className="w-100 px-5 fw-bold h1 d-block text-truncate text-center h1"
                     data-test-element-id='slider-title'>{title}</Tag>
                {
                    subtitle &&
                    <Scrollbar autoHide autoHideTimeout={500} className="h-50 mx-5">
                        <div className="pt-2 px-4 mb-0 text-break font-size-lg">
                            {subtitle}
                        </div>
                    </Scrollbar>
                }
            </div>
        </div>
    );
};