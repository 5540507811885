import React, {useState} from 'react';
import {CustomInputGroup} from './CustomInputGroup';
import {Icon} from './Icon';

type CustomPasswordInputProps = {
	svgIconPath: string;
	inputSize?: 'sm' | 'lg' | 'default',
	iconSize?: number
} & React.InputHTMLAttributes<HTMLInputElement>;

export const CustomPasswordInput = (props: CustomPasswordInputProps) => {
	const {
		inputSize = 'default',
		iconSize = 12,
		svgIconPath,
		...rest
	} = props;

	const [showPassword, setShowPassword] = useState(false);

	const toggleShowPassword = () => {
		setShowPassword(prevState => !prevState)
	}

	return (
		<CustomInputGroup size={inputSize} appendGroup={
			<button type="button" className="btn" onClick={toggleShowPassword} tabIndex={-1}>
				<Icon iconSpritePath={svgIconPath} name={showPassword? 'eye-open' : 'eye-open-slash'} width={iconSize} height={iconSize} />
			</button>
			}>
			<input type={showPassword ? 'text' : 'password'} {...rest}/>
		</CustomInputGroup>
	);
};