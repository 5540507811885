import React from 'react';

type AvatarProps = {
    src: string;
    className?: string;
    alt?: string;
    size?: string;
    title?: string;
}

export const Avatar = ({className, src, alt='Avatar Image', size, title}: AvatarProps) => {
    return (
        <img src={src} className={`avatar ${className ? className : ''} avatar-${size ? size : 'sm'}`} alt={alt}
             title={title}/>
    );
};