import React from 'react';

type CustomInputGroupProps = {
	className?: string;
	prependGroup?: React.ReactNode;
	appendGroup?: React.ReactNode;
	children: React.ReactNode;
	size?: 'sm' | 'lg' | 'default'
}

export const CustomInputGroup = (props: CustomInputGroupProps) => {
	const {className = '', prependGroup, appendGroup, children, size = 'default'} = props;

	return (
		<div
			className={`input-group custom-input-group ${size === 'sm' ? 'input-group-sm' : ''} ${size === 'lg' ? 'input-group-lg' : ''} ${className}`}>
			{
				prependGroup && (
					typeof prependGroup === 'string'
						? <span className="input-group-text custom-input-group-text" id="basic-addon1">{prependGroup}</span>
						: prependGroup
				)
			}
			{children}
			{
				appendGroup && (
					typeof appendGroup === 'string'
						? <span className="input-group-text custom-input-group-text" id="basic-addon1">{appendGroup}</span>
						: appendGroup
				)
			}
		</div>
	);
};