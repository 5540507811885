import React from 'react';
import {DropdownItem, DropdownMenu, DropdownToggle, UncontrolledButtonDropdown} from 'reactstrap';
import range from 'lodash/range';
import {Icon} from './Icon';

const PAGE_SIZES = [10, 20, 30, 40, 50];

const startCount = (pageNumber: number, pageSize: number) => {
    return (pageNumber * pageSize) + 1;
};
const endCount = (pageNumber: number, pageSize: number, total: number) => {
    const count = (pageNumber + 1) * pageSize;
    return total < count ? total : count;
};

export type PaginationLabels = {
    displaying: string;
    of: string;
    results: string;
    pageSize: string;
    next: string;
    previous: string;
}

type PaginationProps = {
    className?: string;
    labels: PaginationLabels;
    pageOperations: {
        previousPage: () => void,
        nextPage: () => void,
        setPageSize: (pageSize: number) => void,
        setPage: (pageNumber: number) => void
    };
    pageStates: {
        total: number,
        pageNumber: number,
        pageSize: number,
        hasPreviousPage?: boolean,
        hasNextPage?: boolean
    },
    svgIconPath: string;
}

export const Pagination = (props: PaginationProps) => {
    const {className = '', labels, pageStates, pageOperations, svgIconPath} = props;

    // pageNumber starts from 0 while displayPageNumber starts from 1
    const {pageNumber, pageSize, total} = pageStates;
    const displayPageNumber = pageNumber + 1;
    const totalPages = Math.ceil(total / pageSize);

    const hasNextPage = typeof pageStates.hasNextPage === 'boolean' ?
        pageStates.hasNextPage :
        displayPageNumber < totalPages;

    const hasPreviousPage = typeof pageStates.hasPreviousPage === 'boolean' ?
        pageStates.hasPreviousPage :
        displayPageNumber > 1;

    return (
        <footer className={`paging ${className}`}>
            <div className="pagination-summary">
                <div className="displaying">
                    {labels.displaying} {startCount(pageNumber, pageSize)} - {endCount(pageNumber, pageSize, total)} {labels.of} {Intl.NumberFormat().format(total)} {labels.results}
                </div>
                <div className="page-sizing">
                    <div>{labels.pageSize}</div>
                    <UncontrolledButtonDropdown a11y={false}>
                        <DropdownToggle color="cancel" caret>
                            {pageSize}
                        </DropdownToggle>
                        <DropdownMenu>
                            {PAGE_SIZES.map((limit, index) => {
                                return (
                                    <DropdownItem key={index} onClick={() => pageOperations.setPageSize(limit)}>
                                        {limit}
                                    </DropdownItem>
                                );
                            })}
                        </DropdownMenu>
                    </UncontrolledButtonDropdown>
                </div>
            </div>

            {totalPages > 1 &&
                <ul className="pagination mb-0">
                    {
                        hasPreviousPage &&
                        <li className="page-item">
                            <button className="btn page-link p-1" onClick={pageOperations.previousPage}>
                                <span className="sr-only">{labels.previous}</span>
                                <Icon width={22}
                                      height={22}
                                      iconSpritePath={svgIconPath}
                                      name="chevron-left"
                                      title={labels.previous}/>
                            </button>
                        </li>
                    }
                    {
                        displayPageNumber - 3 >= 1 && displayPageNumber !== 4 &&
                        <li className="page-item">
                            <button className="btn page-link fw-normal"
                                    onClick={() => pageOperations.setPage(0)}>1
                            </button>
                        </li>
                    }
                    {
                        displayPageNumber - 3 > 1 &&
                        <li className="page-item">
                            <span className="page-link">...</span>
                        </li>
                    }
                    {
                        displayPageNumber > 1 && range(displayPageNumber - (displayPageNumber === 4 ? 3 : 2), displayPageNumber).map(num => (
                            num > 0 &&
                            <li className="page-item d-none d-md-block" key={num}>
                                <button className="btn page-link fw-normal"
                                        onClick={() => pageOperations.setPage(num - 1)}>{num}</button>
                            </li>
                        ))
                    }
                    <li className="page-item active" key={displayPageNumber}>
                        <span className="page-link">{displayPageNumber}</span>
                    </li>
                    {
                        displayPageNumber < totalPages && range(displayPageNumber + 1, displayPageNumber + (displayPageNumber === totalPages - 3 ? 4 : 3)).map(num => (
                            num <= totalPages &&
                            <li className="page-item d-none d-md-block" key={num}>
                                <button className="btn page-link fw-normal"
                                        onClick={() => pageOperations.setPage(num - 1)}>{num}</button>
                            </li>
                        ))
                    }
                    {
                        displayPageNumber + 3 < totalPages &&
                        <li className="page-item">
                            <span className="page-link">...</span>
                        </li>
                    }
                    {
                        displayPageNumber + 3 <= totalPages && displayPageNumber !== totalPages - 3 &&
                        <li className="page-item">
                            <button className="btn page-link fw-normal"
                                    onClick={() => pageOperations.setPage(totalPages - 1)}>{totalPages}</button>
                        </li>
                    }
                    {
                        hasNextPage &&
                        <li className="page-item">
                            <button className="btn page-link p-1" onClick={pageOperations.nextPage}>
                                <span className="sr-only">{labels.next}</span>
                                <Icon width={22}
                                      height={22}
                                      iconSpritePath={svgIconPath}
                                      name="chevron-right"
                                      title={labels.next}/>
                            </button>
                        </li>
                    }
                </ul>
            }
        </footer>
    );
};