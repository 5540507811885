import React from 'react';
import {Popover, PopoverBody} from 'reactstrap';
import {Avatar} from './Avatar';
import type {Boundary, Placement} from '@popperjs/core';

type UserPopoverProps = {
    selector: string;
    members: { name: string, username: string, url: string, avatar: string }[];
    boundariesElement?: Boundary | Element;
    placement?: Placement;
    isOpen: boolean;
    toggle: () => void;
}

export const UserPopover = (props: UserPopoverProps) => {
    const {members, selector, boundariesElement = 'clippingParents', placement = 'right', isOpen = false, toggle} = props;

    return (
        <Popover className="member-list-popover"
                 placement={placement}
                 trigger="legacy"
                 boundariesElement={boundariesElement}
                 target={selector}
                 isOpen={isOpen}
                 toggle={toggle}>
            <PopoverBody>
                <div className="popover-member-list">
                    <ul className="list-unstyled member-list">
                        <li className="member-list-header">Member list</li>
                        {members.map((member, index) =>
                            <li key={index}>
                                <a href={member.url} target="_blank" rel="noopener noreferrer">
                                    <div className="member-avatar-container">
                                        <Avatar src={member.avatar} size="sm" alt=""/>
                                    </div>
                                    <div className="member-list-item-content">
                                        <strong>{member.name}</strong>
                                        {member.username && <small>@{member.username}</small>}
                                    </div>
                                </a>
                            </li>
                        )}
                    </ul>
                </div>
            </PopoverBody>
        </Popover>
    );
}