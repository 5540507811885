import React, {ChangeEventHandler, FocusEventHandler, forwardRef} from 'react';

type RadioProps = {
    inputId: string;
    label: string | JSX.Element;
    // react-hook-form will always support string type value, but not always number
    value?: string;
    name?: string;
    className?: string;
    defaultChecked?: boolean;
    checked?: boolean;
    disabled?: boolean;
    onChange?: ChangeEventHandler<HTMLInputElement>
    onBlur?: FocusEventHandler<HTMLInputElement>;
}

export const Radio = forwardRef<HTMLInputElement, RadioProps>((props, ref) => {
    const {inputId, label, className, ...rest} = props;

    return (
        <div className={`form-check ${className ? className : ''}`}>
            <input type="radio" className="form-check-input" id={inputId} ref={ref} {...rest}/>
            <label className="form-check-label" htmlFor={inputId}>{label}</label>
        </div>
    );
});