import React from 'react';
import {Button as BootstrapButton} from 'reactstrap';

type ButtonProps = {
    [key: string]: any;
    tag?: React.ElementType;
    className?: string;
    color?: string;
    size?: string;
    children: React.ReactNode;
    disabled?: boolean;
    active?: boolean
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

export const Button = (props: ButtonProps) => {
    const {
        tag,
        className = '',
        color = 'primary',
        size,
        children,
        disabled,
        active,
        onKeyDown,
        ...restAttributes
    } = props;

    const handleOnClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
        disabled ? event.preventDefault() : restAttributes.onClick?.(event);
    };

    const handleOnKeyDown = (event: React.KeyboardEvent<HTMLButtonElement>) => {
        if (disabled && (event.key === 'Enter' || event.key === ' ')) {
            event.preventDefault();
        } else {
            onKeyDown && onKeyDown(event);
        }
    };

    return (
        <BootstrapButton tag={tag}
                         className={disabled ? `${className} disabled` : className}
                         aria-disabled={disabled}
                         color={color}
                         size={size}
                         active={active}
                         onKeyDown={handleOnKeyDown}
                         {...(restAttributes.onClick ? {onClick: handleOnClick} : {})}
                         {...restAttributes}>
            {children}
        </BootstrapButton>
    );
};
