import React, {Fragment, memo} from 'react';

type SkeletonProps = {
  count?: number;
  width?: string;
  height?: string;
  circle?: boolean;
  cssClass?: string;
}

export const Skeleton = memo((props: SkeletonProps) => {
  const {count = 1, width, height, circle = false, cssClass = ''} = props;

  const elements = [];

  for (let i = 0; i < count; i++) {
    elements[i] = (
      <Fragment key={i}>
        <span key={i}
              className={`skeleton react-loading-skeleton ${circle ? "rounded-circle" : ""} ${cssClass}`}
              style={{
                width,
                height,
              }}>
          &zwnj;
        </span>&zwnj;
      </Fragment>
    );
  }

  return (
    <Fragment>
      {elements}
    </Fragment>
  );

});