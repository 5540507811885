import React, {Fragment} from 'react';
import {TimeUtil} from '../utils/TimeUtil';
import {Icon} from './Icon';

type ExpiryMessageProps = {
	className?: string;
	expiryDate: string;
	svgIconsPath: string;
	messages: {
		expired: string;
		timeLeft: string;
		daysLeft: string;
		hoursLeft: string;
		minutesLeft: string;
	}
}

export const ExpiryMessage = (props: ExpiryMessageProps) => {
	const {
		className = '',
		expiryDate,
		svgIconsPath,
		messages = {
			expired: 'Expired',
			timeLeft: 'left',
			daysLeft: '{{time}} days',
			hoursLeft: '{{time}} hours',
			minutesLeft: '{{time}} minutes'
		}
	} = props;

	const getTimeFragments = (expiryDate: string) => {
		const timeLeft = TimeUtil.calculateTimeLeft(expiryDate);
		const expiryTimeBlocks = [];
		if (timeLeft.days > 0) {
			expiryTimeBlocks.push(messages.daysLeft.replace('{{time}}', timeLeft.days.toLocaleString()));
		}
		if (timeLeft.hours > 0) {
			expiryTimeBlocks.push(messages.hoursLeft.replace('{{time}}', timeLeft.hours.toLocaleString()));
		}
		if (timeLeft.days <= 0 && timeLeft.minutes) {
			expiryTimeBlocks.push(messages.minutesLeft.replace('{{time}}', timeLeft.minutes.toLocaleString()));
		}
		return expiryTimeBlocks;
	};

	const expiryImminent = () => {
		return TimeUtil.calculateTimeLeft(expiryDate).days < 7;
	};
	return (
		<div
			className={`${expiryImminent() || TimeUtil.isBefore(expiryDate) ? 'expiry-imminent' : ''} d-flex align-items-center p-1 rounded ${className}`}>
			{
				TimeUtil.isBefore(expiryDate)
					? <span>{messages.expired}</span>
					: (<Fragment>
						<Icon iconSpritePath={svgIconsPath} name="stopwatch" className="me-1" height={18} width={18}/>
						<span>
                            {getTimeFragments(expiryDate).join((', '))}
							{' '}
							{messages.timeLeft}
                        </span>
					</Fragment>)
			}
		</div>
	);
};