import React, {ChangeEvent, ChangeEventHandler, forwardRef, useImperativeHandle, useRef} from 'react';

type SwitchProps = {
    label: string | React.ReactNode;
    inputId: string;
    name: string;
    onText: string;
    offText: string;
    checked?: boolean;
    defaultChecked?: boolean;
    disabled?: boolean;
    onChange?: ChangeEventHandler<HTMLInputElement>;
    screenReaderLabel?: string;
};

export const Switch = forwardRef<HTMLInputElement, SwitchProps>((props, forwardedRef) => {
    const {
        label,
        inputId,
        name,
        onText,
        offText,
        checked,
        defaultChecked,
        disabled,
        onChange,
        screenReaderLabel
    } = props;

    const ref = useRef<HTMLInputElement | null>(null);

    useImperativeHandle(forwardedRef, () => ref.current as HTMLInputElement);

    const onChangeChecked = (event: ChangeEvent<HTMLInputElement>) => {
        if (onChange) {
            onChange(event)
        }
    }

    return (
        <div className="ideascale-switch">
            <input type="checkbox" id={inputId} ref={ref} defaultChecked={defaultChecked}
                   name={name} checked={checked} disabled={disabled} onChange={onChangeChecked}
            />
            <label htmlFor={inputId}>
                {
                    label
                        ? <span className="ideascale-switch-label-text text-break">{label}</span>
                        : <span className="sr-only">{screenReaderLabel}</span>
                }
                <i className="ideascale-switch-switch" aria-hidden="true" data-checked-text={onText}
                   data-unchecked-text={offText}/>
            </label>
        </div>
    );
});
