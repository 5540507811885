import React from 'react';
import {Icon} from "../Icon";
import {SliderArrowProps} from "./IdeascaleSlider";

export const SliderLeftArrow = (props: SliderArrowProps) => {
    const {onClick, svgIconPath} = props;
    return (
        <button className={`${onClick === null ? 'd-none' : ''} slider-arrows slider-arrows-prev`}
                onClick={onClick}>
            <Icon iconSpritePath={svgIconPath} name="chevron-left" width={30}
                  height={30} fill={'#fff'}/>
            <span className="sr-only">Previous</span>
        </button>
    );
};
