import React, {Ref, useState} from 'react';
import {ActionMeta, GetOptionLabel, InputActionMeta, OnChangeValue, OptionsOrGroups, PropsValue} from 'react-select';
import {AsyncPaginate, LoadOptions} from 'react-select-async-paginate';
import {
    ClearIndicator,
    DropdownIndicator,
    Group,
    GroupHeading,
    IndicatorSeparator,
    Menu,
    MultiValue,
    NoOptionsMessage,
    Option,
    SingleValue
} from './IdeascaleSelectComponents';
import {OptionType} from './OptionType';

interface IdeascaleSelectWithPaginationProps {
    loadOptions: LoadOptions<any, any, any>;
    options?: OptionsOrGroups<any, any>,
    defaultOptions?: boolean;
    defaultValue?: OnChangeValue<OptionType, boolean>;
    additional?: any;
    name?: string;
    onChange?: (value: OnChangeValue<OptionType, boolean>, action: ActionMeta<OptionType>) => void;
    onInputChange?: (newValue: string, actionMeta: InputActionMeta) => void;
    onBlur?: () => void;
    onFocus?: () => void;
    onMenuOpen?: () => void;
    onMenuClose?: () => void;
    isMulti?: boolean;
    autoFocus?: boolean;
    isCreatable?: boolean;
    ref?: any;
    placeholder?: string;
    inputId?: string;
    disabled?: boolean;
    closeMenuOnSelect?: boolean;
    menuIsOpen?: boolean;
    getOptionLabel?: GetOptionLabel<OptionType>
    value?: PropsValue<OptionType>;
    cacheUniqs?: readonly any[];
    hideSelectedOptions?: boolean;
    noOptionsMessage?: (obj: { inputValue: string }) => string | null;
    loadingMessage?: (obj: { inputValue: string }) => string | null;
    isClearable?: boolean;
    ariaLabel?: string;
    tabSelectsValue?: boolean
}

export const IdeascaleSelectWithPagination = React.forwardRef((props: IdeascaleSelectWithPaginationProps, ref: Ref<any>) => {
    const [dialogMarker, setDialogMarker] = useState(false);

    const resolveAccessibleLabel = (option: OptionType) => {
        return props.getOptionLabel ? props.getOptionLabel(option) : option.accessibleLabel || option.label;
    };

    return (
        <AsyncPaginate {...props}
                       onMenuOpen={() => {
                           setDialogMarker(true);
                           props.onMenuOpen?.();
                       }}
                       onMenuClose={() => {
                           props.onMenuClose?.();
                           setTimeout(() => {
                               setDialogMarker(false);
                           }, 500);
                       }}
                       getOptionLabel={resolveAccessibleLabel}
                       isDisabled={props.disabled}
                       className={`ideascale-select ${dialogMarker ? 'additional-dialog-open' : ''}`}
                       classNamePrefix="ideascale-select"
                       selectRef={ref}
                       isClearable={props.isClearable}
                       debounceTimeout={500}
                       menuIsOpen={props.menuIsOpen}
                       value={props.value}
                       aria-label={props.ariaLabel}
                       components={{
                           NoOptionsMessage,
                           Group,
                           Option,
                           GroupHeading,
                           Menu,
                           MultiValue,
                           SingleValue,
                           IndicatorSeparator,
                           ClearIndicator,
                           DropdownIndicator
                       }}/>
    );
});