import React, {useEffect, useState} from 'react';
import {Alert as BootstrapAlert} from 'reactstrap';
import {Icon} from './Icon';

type propsType = {
	message: string | JSX.Element;
	variant: 'success' | 'danger' | 'info' | 'warning';
	svgIconsPath: string;
	open?: boolean;
	className?: string;
	closeable?: boolean;
	onClose?: () => void;

}
export const Alert = (props: propsType) => {
	const [visible, setVisible] = useState(false);

	const {
		message,
		variant,
		svgIconsPath,
		open = false,
		className = '',
		closeable = true,
		onClose = () => {
			setVisible(false);
		}
	} = props;

	useEffect(() => {
		setVisible(open);
	}, [open]);

	let icon: JSX.Element;
	switch (variant) {
		case 'success':
			icon =
				<Icon className="position-relative pos-top-n1 is-icon icon-alert-success" name="check-outlined-circle"
					  iconSpritePath={svgIconsPath}
					  fill="#1E4620"/>;
			break;
		case 'danger':
			icon = <Icon className="position-relative pos-top-n1 is-icon icon-alert-danger" name="exclamation-outlined-circle"
						 iconSpritePath={svgIconsPath}
						 fill="#611A15"/>;
			break;
		case 'info':
			icon = <Icon className="position-relative pos-top-n1 is-icon icon-alert-info" name="info-outlined-circle"
						 iconSpritePath={svgIconsPath}
						 fill="#222F74"/>;
			break;
		case 'warning':
			icon = <Icon className="position-relative pos-top-n1 is-icon icon-alert-warning" name="exclamation-outlined-triangle"
						 iconSpritePath={svgIconsPath}
						 fill="#663C00"/>;
			break;
	}

	const toggleProps: { [key: string]: () => void } = {};
	if (closeable) {
		toggleProps.toggle = onClose;
	}

	return (
		<BootstrapAlert className={className} color={variant} {...toggleProps}
						isOpen={visible} closeAriaLabel="Dismiss alert message" lang="en">
			{icon && icon}
			{message}
		</BootstrapAlert>
	);
};
