import React, {forwardRef, ReactElement, Ref, useCallback, useMemo, useState} from 'react';
import Creatable, {CreatableProps} from 'react-select/creatable';
import {AsyncPaginate, ComponentProps, UseAsyncPaginateParams, withAsyncPaginate} from 'react-select-async-paginate';
import {GroupBase, Options, OptionsOrGroups, SelectInstance} from 'react-select';
import {OptionType} from './OptionType';
import {
    ClearIndicator,
    DropdownIndicator,
    IdeascaleTag,
    IndicatorSeparator,
    Menu,
    Option,
} from './IdeascaleSelectComponents';

const stringToOptionType = (options: string[]) => {
    return options.map((option: string) => {
        return {value: option, label: option};
    });
};

type PageParameters = {
    page: number;
    term?: string;
    limit?: number;
}

const replaceSpecialCharacterAndSpaceWithDash = (text: string) => {
    if (text) {
        return text.trim().replace(/[!@#$%^&*()_+{}\[\]:;<>,.?~\\|\-="'¡¿§±\s]/gu, '-');
    } else {
        return text;
    }
};

type AsyncPaginateCreatableProps<OptionType,
    Group extends GroupBase<OptionType>,
    Additional,
    IsMulti extends boolean,
> =
    & CreatableProps<OptionType, IsMulti, Group>
    & UseAsyncPaginateParams<OptionType, Group, Additional>
    & ComponentProps<OptionType, Group, IsMulti>;

type AsyncPaginateCreatableType = <OptionType,
    Group extends GroupBase<OptionType>,
    Additional,
    IsMulti extends boolean = false,
>(props: AsyncPaginateCreatableProps<OptionType, Group, Additional, IsMulti>) => ReactElement;

const CreatableAsyncPaginate = withAsyncPaginate(Creatable) as AsyncPaginateCreatableType;

type TagAsyncSelectProps = {
    fetchOptions: (pageParams: PageParameters) => Promise<{
        content: string[];
        hasMore: boolean;
        pageNo: number;
        pageSize: number;
        totalElements?: number
    }>;
    onChange: (tags: OptionType[] | null) => void;
    name: string;
    inputId: string;
    value: OptionType[]
    isCreatable?: boolean;
    placeholder?: string;
    createLabel?: string;
    enterLabel?: string;
    noTagsMessage?: string;
    disabled?: boolean;
}

export const TagAsyncSelect = forwardRef((props: TagAsyncSelectProps, ref: Ref<SelectInstance<OptionType, boolean, GroupBase<OptionType>>>) => {
    const [dialogMarker, setDialogMarker] = useState(false);

    const {
        fetchOptions,
        onChange,
        name,
        inputId,
        placeholder = 'Add tags',
        isCreatable = false,
        value,
        createLabel = 'Create',
        noTagsMessage = 'Tags not found',
        enterLabel = 'Enter',
        disabled = false
    } = props;

    const loadTags = useCallback(async (term: string, _prevOptions: OptionsOrGroups<OptionType, GroupBase<OptionType>>, additionalParams: any) => {
        try {
            const {page = 0} = additionalParams;
            const {content, hasMore} = await fetchOptions({term, page});
            const finalOptions = stringToOptionType(content);
            return {
                options: finalOptions as any,
                hasMore,
                additional: {
                    page: page + 1,
                }
            };
        } catch (e: any) {
            window.console.log(e);
        }
        return {
            options: []
        };
    }, [fetchOptions]);

    const onChangeTag = useCallback((inputValues: any) => {
        const filteredValues = inputValues?.reduce((list: OptionType[], current: OptionType) => {
            const index = list.findIndex((option: OptionType) =>
                option.value.toLowerCase() === current.value.toLowerCase())
            if (index !== -1) {
                list[index] = current;
            } else {
                list.push(current);
            }
            return list;
        }, [])
        onChange(filteredValues);
    }, [onChange]);

    const onCreateOption = useCallback(async (inputValue: any) => {
        const newTag = replaceSpecialCharacterAndSpaceWithDash(inputValue);
        if (newTag) {
            const newOption = {
                label: newTag,
                value: newTag
            };

            const newTags = [...value.filter(tag => tag.value.toLowerCase() !== newTag.toLowerCase()), newOption];
            onChange(newTags);
        }
    }, [onChange, value]);

    const formatCreateLabel = useCallback((value: string) => {
        return (
            <div className="d-flex justify-content-between">
                <div><strong>{createLabel}: </strong> {replaceSpecialCharacterAndSpaceWithDash(value)}</div>
                <span><span className="position-relative top-2" role="presentation"
                            lang="en">&#9166;</span> {enterLabel}</span>
            </div>
        );
    }, [createLabel]);

    const commonProps = useMemo(() => (
        {
            isClearable: true,
            placeholder,
            className: `ideascale-select ${dialogMarker ? 'additional-dialog-open' : ''}`,
            classNamePrefix: 'ideascale-select',
            debounceTimeout: 500,
            isMulti: true,
            name,
            inputId,
            isDisabled: disabled,
            value: value as any[],
            loadOptions: loadTags,
            onChange: (value: any) => onChangeTag(value),
            additional: {
                page: 0
            },
            defaultMenuIsOpen: false,
            noOptionsMessage: (obj: { inputValue: string }) => obj.inputValue.trim() !== '' ? noTagsMessage : null,
            closeMenuOnSelect: false,
            components: {
                Option,
                Menu,
                MultiValue: IdeascaleTag,
                DropdownIndicator,
                ClearIndicator,
                IndicatorSeparator,
            },
            selectRef: ref,
            page: 0
        }
    ), [disabled, inputId, loadTags, name, noTagsMessage, onChangeTag, placeholder, ref, value]);

    const isValidNewOption = useCallback((inputVal: string, values: Options<OptionType>, options: OptionsOrGroups<OptionType, GroupBase<OptionType>>) => {
        const inputValOnCreation = replaceSpecialCharacterAndSpaceWithDash(inputVal);
        const existInValues = !values.some(val =>
            val.value.toLowerCase() === inputValOnCreation.toLowerCase());
        const existInOptions = !options.some(opt =>
            opt.label?.toLowerCase() === inputValOnCreation.toLowerCase());
        return inputValOnCreation.length > 0 && existInValues && existInOptions;
    }, []);

    return (
        isCreatable
            ? <CreatableAsyncPaginate
                autoFocus
                {...commonProps}
                onMenuOpen={() => setDialogMarker(true)}
                onMenuClose={() => setDialogMarker(false)}
                formatCreateLabel={formatCreateLabel}
                isValidNewOption={isValidNewOption}
                onCreateOption={onCreateOption}
                createOptionPosition="first"/>
            : <AsyncPaginate {...commonProps} autoFocus/>
    );
});


