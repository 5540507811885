import {Theme} from '../enums';
import {StorageUtil} from './StorageUtil';

export class AppThemeUtil {
	static APP_THEME_KEY = 'ideascaleAppTheme';

	static isDarkThemePreferred(appTheme: Theme, isAnonymous: boolean = true) {
		let dark = false;
		if (appTheme === Theme.DARK) {
			dark = true;
		} else if (appTheme === Theme.SYSTEM) {
			if (isAnonymous && StorageUtil.getItem(AppThemeUtil.APP_THEME_KEY)) {
				dark = StorageUtil.getItem(AppThemeUtil.APP_THEME_KEY) === Theme.DARK;
			} else {
				dark = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
			}
		}
		return dark;
	}

	static toggleTheme(dark: boolean) {
		const htmlEl = document.querySelector('html');
		if (htmlEl) {
			if (dark) {
				htmlEl.classList.remove('light-theme', 'dark-theme');
				htmlEl.classList.add('dark-theme');
			} else {
				htmlEl.classList.remove('dark-theme', 'light-theme');
				htmlEl.classList.add('light-theme');
			}
		}
	}
}