import React, {ButtonHTMLAttributes, ReactNode, useMemo} from 'react';
import {Modal as BootstrapModal, ModalHeader} from 'reactstrap';
import {ActionButton} from './ActionButton';
import {KEYS} from '../enums/CommonEnums';
import {CommonUtil} from "../utils";

type ModalProps = {
    [key: string]: any;
    isOpen: boolean;
    children: ReactNode;
    toggle?: () => void;
    title?: ReactNode;
    scrollable?: boolean;
    className?: string;
    modalBodyClassName?: string;
    modalFooterClassName?: string;
    customFooter?: ReactNode;
    closeOnEscape?: boolean;
    cancelButton?: {
        btnLabel: string;
        btnProps?: ButtonHTMLAttributes<HTMLButtonElement>
    }
    primaryButton?: {
        loading?: boolean;
        btnLabel: string;
        btnProps?: ButtonHTMLAttributes<HTMLButtonElement>,
    };
    autoFocus?: boolean;
    trapFocus?: boolean | undefined;
    onEnter?: () => void;
    backdrop?: boolean | 'static';
    modalHeaderId?: string;
    modalBodyId?: string;
    size?: 'sm' | 'lg' | 'xl';
    showHeader?: boolean;
    showHeaderCloseButton?: boolean;
} & Omit<React.HTMLAttributes<HTMLElement>, 'title'>;

export const Modal = (props: ModalProps) => {
    const {
        toggle,
        children,
        title = null,
        modalBodyClassName,
        modalFooterClassName,
        customFooter,
        cancelButton,
        modalHeaderId,
        modalBodyId,
        backdrop = 'static',
        primaryButton,
        size = '',
        closeOnEscape = true,
        showHeader = true,
        showHeaderCloseButton = true,
        trapFocus = true,
        ...rest
    } = props;

    const headerId = useMemo(() => {
        return modalHeaderId || CommonUtil.uuid()
    }, [])

    const handleEscapeKey = (e: React.KeyboardEvent<HTMLDivElement>) => {
        const additionalDialog = (e.target as Element).closest('.modal')?.querySelector('.additional-dialog-open');
        if (additionalDialog && e.key === KEYS.ESCAPE) {
            e.stopPropagation();
        }
    };

    return (
        <BootstrapModal {...rest} backdrop={backdrop} size={size} labelledBy={headerId}
                        toggle={closeOnEscape ? toggle : undefined} keyboard={closeOnEscape} trapFocus={trapFocus}>
            {
                showHeader && (title || toggle) &&
                <ModalHeader aria-hidden={!title} tag="div"
                             toggle={showHeaderCloseButton ? toggle : undefined} id={headerId}>
                    {
                        //For Accessibility
                        title
                            ? typeof title === 'string'
                                ? <h2 className="h5 modal-title-text">{title}</h2>
                                : title
                            : <span className="sr-only">Modal</span>
                    }
                </ModalHeader>
            }
            <div
                onKeyUp={handleEscapeKey}
                className={`modal-body${modalBodyClassName ? ' ' + modalBodyClassName : ''}`}
                {...(modalBodyId ? {id: modalBodyId} : {})}
                {...(rest.scrollable ? {tabIndex: -1} : {})}>
                {children}
            </div>
            {
                customFooter
                    ? <div className={`modal-footer ${modalFooterClassName ? modalFooterClassName : ''}`}>
                        {customFooter}
                    </div>
                    :
                    (
                        (cancelButton || primaryButton) &&
                        <div className={`modal-footer ${modalFooterClassName ? modalFooterClassName : ''}`}>
                            {
                                cancelButton &&
                                <button className="btn btn-cancel"
                                        onClick={cancelButton.btnProps?.onClick ? cancelButton.btnProps.onClick : toggle} {...cancelButton.btnProps}>
                                    {cancelButton.btnLabel}
                                </button>
                            }
                            {
                                primaryButton &&
                                <ActionButton loading={primaryButton.loading || false} {...primaryButton.btnProps}>
                                    {primaryButton.btnLabel}
                                </ActionButton>
                            }
                        </div>
                    )
            }
        </BootstrapModal>
    );
};
