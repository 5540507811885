import React, {Ref, useState} from 'react';
import Select, {GetOptionLabel, InputActionMeta, OnChangeValue, OptionsOrGroups} from 'react-select';
import {OptionType} from './OptionType';
import {
    ClearIndicator,
    DropdownIndicator,
    Group,
    GroupHeading,
    IndicatorSeparator,
    Menu,
    MultiValue,
    Option,
    NoOptionsMessage
} from './IdeascaleSelectComponents';

interface IdeascaleSelectProps {
    options: OptionsOrGroups<OptionType, any>,
    defaultValue?: OnChangeValue<OptionType, boolean>;
    onChange?: (value: OnChangeValue<OptionType, boolean>) => void;
    name?: string;
    onInputChange?: (newValue: string, actionMeta: InputActionMeta) => void;
    onBlur?: () => void;
    onFocus?: () => void;
    onMenuOpen?: () => void;
    onMenuClose?: () => void;
    isMulti?: boolean;
    autoFocus?: boolean;
    placeholder?: string;
    inputId?: string;
    disabled?: boolean;
    closeMenuOnSelect?: boolean;
    getOptionLabel?: GetOptionLabel<OptionType>;
    value?: OnChangeValue<OptionType, boolean>;
    isClearable?: boolean;
    ariaLabel?: string;
    menuIsOpen?: boolean;
}

export const IdeascaleSelect = React.forwardRef((props: IdeascaleSelectProps, ref: Ref<any>) => {
    const [dialogMarker, setDialogMarker] = useState(false);

    const resolveAccessibleLabel = (option: OptionType) => {
        return props.getOptionLabel ? props.getOptionLabel(option) : option.accessibleLabel || option.label;
    };

    return (
        <Select {...props}
                menuIsOpen={props.menuIsOpen}
                onMenuOpen={() => {
                    setDialogMarker(true);
                    props.onMenuOpen?.();
                }}
                onMenuClose={() => {
                    props.onMenuClose?.();
                    setTimeout(() => {
                        setDialogMarker(false);
                    }, 500);
                }}
                getOptionLabel={resolveAccessibleLabel}
                isDisabled={props.disabled}
                className={`ideascale-select ${dialogMarker ? 'additional-dialog-open' : ''}`}
                classNamePrefix="ideascale-select"
                isClearable={props.isClearable}
                ref={ref}
                aria-label={props.ariaLabel}
                components={{
                    Menu,
                    Group,
                    GroupHeading,
                    Option,
                    MultiValue,
                    DropdownIndicator,
                    ClearIndicator,
                    IndicatorSeparator,
                    NoOptionsMessage
                }}/>
    );
});
