import React, {Fragment} from 'react';
import {DropdownMenu as BootstrapDropdownMenu, DropdownMenuProps as BootstrapDropdownMenuProps} from 'reactstrap';
import {DropdownWrapper} from './DropdownWrapper';

type WithDrawerAbleProps = {
	drawerAble: true;
	container: string;
}

type WithoutDrawerAbleProps = { drawerAble?: false }

type ConditionalProps = | WithDrawerAbleProps | WithoutDrawerAbleProps

interface CommonDropdownMenuProps extends BootstrapDropdownMenuProps {
	dropdownWrapperPlacement?: 'left' | 'right';
	dropdownWrapperClassName?: string;
}

type DropdownMenuProps = CommonDropdownMenuProps & ConditionalProps

export const DropdownMenu = (props: DropdownMenuProps) => {
	const {children, container, drawerAble, dropdownWrapperPlacement, dropdownWrapperClassName, ...rest} = props;

	return (
		<Fragment>
			{
				drawerAble && typeof container === 'string' && !!container &&
				<DropdownWrapper containerId={container} placement={dropdownWrapperPlacement}
								 className={dropdownWrapperClassName}/>
			}
			<BootstrapDropdownMenu container={container} {...rest}>{children}</BootstrapDropdownMenu>
		</Fragment>
	);
};