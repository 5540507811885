import React from 'react';
import {Skeleton} from './Skeleton';
import {ParagraphSkeleton} from './ParagraphSkeleton';

type MediaObjectSkeletonType = {
    paragraphRows?: number;
    avatarSize?: string;
};

export const MediaObjectSkeleton = ({paragraphRows = 4, avatarSize = '30px'}: MediaObjectSkeletonType) => {
    return (
        <div className="media">
            <div className="me-2">
                <Skeleton circle={true} width={avatarSize} height={avatarSize}/>
            </div>
            <div className="media-body">
                <div className="text-sm mb-1 ms-2">
                    <Skeleton width="60%"/>
                </div>
                <div className="card rounded-1 ms-2">
                    <div className="card-body">
                        <h5 className="mt-0 mb-4"><Skeleton/></h5>
                        <ParagraphSkeleton rows={paragraphRows}/>
                    </div>
                </div>
            </div>
        </div>
    )
}