import React, {Fragment} from 'react';
import {components} from 'react-select';

export const GroupHeading = (props: any) => (
    <components.GroupHeading className="option-group-heading" {...props}>
        <div className="option-group-heading">
            {props.data.header ? props.data.header : props.children}
        </div>
    </components.GroupHeading>
);

export const Group = (props: any) => (
    <div className="grouped-option">
        <components.Group className={`option-group ${props.data.label && 'has-group-name'}`} {...props}/>
        {props.data.separator}
    </div>
);

export const NoOptionsMessage = (props: any) => (
    <div role="option">
        <components.NoOptionsMessage className="no-option" {...props}>
            {props.children}
        </components.NoOptionsMessage>
    </div>
);

export const Option = (props: any) => (
    <Fragment>
        {
            props.data.labelAsJsx || props.children
                ? (
                    <components.Option {...props}
                                       className={`custom-option d-flex align-items-center ${props.isSelected && 'option-selected'} ${props.isFocused && 'option-focused'}`}>
                        {
                            props.data.image &&
                            <div className="option-icon">
                                <img src={props.data.image} alt=""/>
                            </div>
                        }
                        <div className={`option-label ${props.data.image ? '' : 'w-100'} text-truncate`}
                             {...(props.children ? {title: props.children} : {})}>
                            {props.data.labelAsJsx ? props.data.labelAsJsx : props.children}
                        </div>
                    </components.Option>
                )
                : null
        }
    </Fragment>
);

export const DropdownIndicator = (props: any) => (
    <components.DropdownIndicator {...props}>
        <span className={props.selectProps.menuIsOpen ? 'triangle triangle-up' : 'triangle triangle-down'}/>
    </components.DropdownIndicator>
);

export const IndicatorSeparator = () => null;

export const ClearIndicator = (props: any) => {
    const {innerProps: {ref, ...restInnerProps}} = props;
    return (
        <div {...restInnerProps} ref={ref} aria-hidden={false}>
            <button className="btn btn-select-clear" title="Remove All" lang="en" type="button" tabIndex={-1}
                    style={{fontSize: '18px', marginTop: '-3px', padding: '0 10px'}}>
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
    );
};

export const Menu = (props: any) => {
    return (
        <components.Menu {...props} className="ideascale-select-menu">
            {props.children}
        </components.Menu>
    );
};

export const MultiValue = (props: any) => {
    return (
        <div className="multi-value-wrap" key={props.data.value}>
            <components.MultiValue className="multi-value" {...props}>
                {props.data.classification && props.data.labelAsJsx ? props.data.labelAsJsx : props.children}
            </components.MultiValue>
        </div>
    );
};

export const SingleValue = (props: any) => {
    return (
        <components.SingleValue className="single-value" {...props}>
            {props.data.labelAsJsx ? props.data.labelAsJsx : props.children}
        </components.SingleValue>
    );
};

export const IdeascaleTag = (props: any) => {
    const {removeProps, data} = props;
    return (
        <span className="tag">
            <span className="tag-label">{data.label}</span>
            <button type="button"
                    className="close"
                    aria-label="Remove"
                    onClick={removeProps.onClick}
                    onMouseDown={removeProps.onMouseDown}
                    onTouchEnd={removeProps.onTouchEnd}>
					<span aria-hidden="true">&times;</span>
            </button>
        </span>
    );
};
