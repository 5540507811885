import React, {forwardRef} from 'react';

type IdeascaleCustomRadioProps = {
	value: string;
	inputId: string;
	defaultChecked: string;
	renderLabel: (type: any) => JSX.Element;
	onClick?: (value: string) => void;
	name?: string;
	disabled?: boolean;
}

export const IdeascaleCustomRadio = forwardRef<HTMLInputElement, IdeascaleCustomRadioProps>((props, ref) => {
	const {value, inputId, name, defaultChecked, renderLabel, onClick, disabled = false, ...rest} = props;
	return (
		<div className="ideascale-custom-radio d-inline-flex">
			<input id={inputId}
				   type="radio"
				   name={name}
				   value={value}
				   ref={ref}
				   onClick={() => onClick?.(value)}
				   defaultChecked={defaultChecked === value}
				   checked={onClick ? defaultChecked === value : undefined}
				   disabled={disabled}
				   {...rest}
			/>
			<label className="me-2 p-2 rounded cursor-pointer d-flex align-items-center"
				   htmlFor={inputId}>
						<span className="d-inline-flex align-items-center">
							{renderLabel(value)}
						</span>
			</label>
		</div>
	);
});