import React, {Fragment, useState} from 'react';
import {
	Carousel,
	CarouselItem,
	CarouselIndicators
} from 'reactstrap';
import {Icon} from './Icon';


type SliderProps = {
	sliderItems: { imageSrc: string, content: JSX.Element }[];
	svgIconSprite: string;
}

export const Slider = (props: SliderProps) => {
	const {sliderItems, svgIconSprite} = props;

	const [activeIndex, setActiveIndex] = useState(0);
	const [animating, setAnimating] = useState(false);

	const next = () => {
		if (!animating) {
			const nextIndex = activeIndex === sliderItems.length - 1 ? 0 : activeIndex + 1;
			setActiveIndex(nextIndex);
		}
	};

	const previous = () => {
		if (!animating) {
			const nextIndex = activeIndex === 0 ? sliderItems.length - 1 : activeIndex - 1;
			setActiveIndex(nextIndex);
		}
	};

	const goToIndex = (newIndex: number) => {
		if (!animating) {
			setActiveIndex(newIndex);
		}
	};

	const renderSlides = sliderItems.map((item, index) => {
		return (
			<CarouselItem
				onExiting={() => setAnimating(true)}
				onExited={() => setAnimating(false)}
				key={index}>
				<div className="carousel-caption-overlay-bg"
					 style={{backgroundImage: `url(${item.imageSrc ? item.imageSrc : ''})`}}/>
				<div className="carousel-caption">
					{item.content}
				</div>
			</CarouselItem>
		);
	});

	return (
		<section className="ideascale-slider">
			<Carousel
				activeIndex={activeIndex}
				next={next}
				previous={previous}>
				{
					(sliderItems.length < 11 && sliderItems.length > 1) &&
					<CarouselIndicators items={sliderItems} activeIndex={activeIndex} onClickHandler={goToIndex}/>
				}

				{renderSlides}

				{
					sliderItems.length > 1 &&
					<Fragment>
						<button className="carousel-control-prev border-0" tabIndex={0} onClick={previous}>
							<Icon iconSpritePath={svgIconSprite} name="chevron-left"/>
							<span className="sr-only">Previous</span>
						</button>
						<button className="carousel-control-next border-0" tabIndex={0} onClick={next}>
							<Icon iconSpritePath={svgIconSprite} name="chevron-right"/>
							<span className="sr-only">Next</span>
						</button>
					</Fragment>
				}
			</Carousel>
		</section>
	);
};
